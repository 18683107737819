import {DatabaseItem} from './store/RootStore';

export const StatutoryEnhancementTypes : DatabaseItem[] = [
  {
    id: 1,
    name: 'Resolution Accredited'
  },
  {
    id: 2,
    name: 'Law Society Family Panel Advanced'
  },
  {
    id: 3,
    name: 'Law Society Children Panel'
  }
]

export enum TextAlignment {
  LEFT = 'left',
  RIGHT = 'right'
}