export default {
  RESIZE: 'resize',
  SCROLL: 'scroll',
  WHEEL: 'wheel',
  LOAD: 'load',
  CLICK: 'click',
  MOUSE_ENTER: 'mouseenter',
  MOUSE_OVER: 'mouseover',
  MOUSE_LEAVE: 'mouseleave',
  MOUSE_DOWN: 'mousedown',
  MOUSE_UP: 'mouseup',
  MOUSE_MOVE: 'mousemove',
  TOUCH_ENTER: 'touchenter',
  TOUCH_LEAVE: 'touchleave',
  TOUCH_START: 'touchstart',
  TOUCH_END: 'touchend',
  TOUCH_MOVE: 'touchmove',
  KEY_DOWN: 'keydown',
  KEY_UP: 'keyup',
  CHANGE: 'change',
  FOCUS: 'focus',
  BLUR: 'blur',
  ERROR: 'error',
  SUBMIT: 'submit',
  POP_STATE: 'popstate'
}
