import React, {ReactNode} from 'react';

import DatabaseItemDropDownListItem from './DatabaseItemDropDownListItem';
import LoadingCircle from '@component/loading/LoadingCircle';
import ScrollEndTrigger from '@component/ScrollEndTrigger';
import {DatabaseItem} from '@store/RootStore';

type DatabaseItemDropDownListProps<T> = {
  data: DatabaseItem<T>[];
  value: DatabaseItem<T>;
  busy?: boolean;
  usingSearch?: boolean;
  displayItemAs?: (item: DatabaseItem<T>) => ReactNode;
  onSelect: (value:  DatabaseItem<T>) => void;
  onGetMore: () => void;
  keyGenerator: (t: DatabaseItem<T>) => string;
  selectedCheck: (value: DatabaseItem<T>, t: DatabaseItem<T>) => boolean;
}

const DatabaseItemDropDownList = <T, >({
                                      data,
                                      value,
                                      busy,
                                      usingSearch,
                                      displayItemAs,
                                      onSelect,
                                      onGetMore,
                                      keyGenerator,
                                      selectedCheck
                                    } : DatabaseItemDropDownListProps<T>) => {
  const focusHandler = (index: number) => {
    if (index === data.length - 1) {
      onGetMore();
    }
  }

  return (
    <ScrollEndTrigger<T>
      busy={ busy }
      className='database-item-drop-down__list'
      deps={ data }
      onEndTrigger={ onGetMore }>
      {
        data?.length > 0 ?
          data.map((item: T, index: number) => (
            <DatabaseItemDropDownListItem
              key={ keyGenerator(item) }
              selected={ selectedCheck(value, item) }
              onClick={ () => onSelect(item) }
              onFocus={ () => focusHandler(index) }
              displayAs={ displayItemAs(item) }
            />
          ))
          :
          (!busy && !usingSearch) ?
            <p className='empty'>There are no items to show</p>
            :
            !busy &&
            <p className='empty'>There are no results matching your search</p>
      }
      {
        busy ?
          <div className='loading-row flex flex-row flex-align-center'>
            <LoadingCircle />
            <span className='title'>
              { usingSearch ? 'Searching...' : 'Loading...' }
            </span>
          </div>
          :
          <span className='end-spacer'>&nbsp;</span>
      }
    </ScrollEndTrigger>
  )
}

export default DatabaseItemDropDownList;
