import React, {ReactNode} from 'react';

type StoreDropDownListItemProps = {
  selected: boolean;
  displayAs: ReactNode;
  onClick: () => void;
  onFocus: () => void;
}

const DatabaseItemDropDownListItem = ({
                                          displayAs,
                                          selected,
                                          onClick,
                                          onFocus
                                        } : StoreDropDownListItemProps) => {
  return (
    <button className='database-item-drop-down__item'
            data-arrow-focusable={ true }
            aria-selected={ selected }
            tabIndex={ 0 }
            aria-label={ `Select item` }
            type='button'
            onFocus={ onFocus }
            onClick={ onClick }>
      { displayAs }
    </button>
  )
};

export default DatabaseItemDropDownListItem;
