import React, {useEffect, useRef} from 'react';
import FocusTrap from 'focus-trap-react';

import {DropDownContext} from '@context/DropDownContext';
import Events from '@events';

type DropDownContentProps = {
  className?: string;
  onClose: () => void;
  openingPosition: string;
  valueRef: HTMLElement;
  children: React.ReactNode;
}

const DropDownContent = ({
                           className,
                           onClose,
                           openingPosition,
                           valueRef,
                           children
                         } : DropDownContentProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const checkClickOutside = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    return target !== valueRef;
  }

  useEffect(() => {
    containerRef.current?.addEventListener(Events.CLICK, clickHandler)
    return () => containerRef.current?.removeEventListener(Events.CLICK, clickHandler)
  }, []);

  const clickHandler = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (target.tagName === 'A' || target.parentElement.tagName === 'A') {
      onClose();
    }
  }

  return (
    <FocusTrap focusTrapOptions={{
      clickOutsideDeactivates: checkClickOutside,
      onDeactivate: onClose
    }}>
      <div className={`drop-down__content ${className ?? ''}`}
           data-opening-position={ openingPosition }
           ref={ containerRef }>
        <DropDownContext.Provider value={{ onClose }} >
          { children }
        </DropDownContext.Provider>
      </div>
    </FocusTrap>
  )
};

export default DropDownContent;