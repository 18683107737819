import {RootStore} from './RootStore';
import {action, makeObservable, observable} from 'mobx';
import {ContactTypes} from './ContactStore';
import {ApiRoutes} from '../api/API';

class ApiStore {
  busyRoutes: string[];
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      busyRoutes: observable,
      setBusy: action,
      removeBusy: action,
      checkBusy: false,
      rootStore: false
    });

    this.busyRoutes = [];
    this.rootStore = rootStore
  }

  setBusy(route: string) {
    if (!this.busyRoutes.includes(route))
      this.busyRoutes.push(route);
  }

  removeBusy(route: string) {
    this.busyRoutes = this.busyRoutes.filter(r => route !== r);
  }

  checkBusy(route: string, exact?: boolean): boolean {
    if (exact) return this.busyRoutes.includes(route);

    // Loop to catch things like /self extensions etc
    for (const busyRoute of this.busyRoutes) {
      if (busyRoute.includes(route)) return true;
    }

    return false;
  }

  checkContactsBusy(contactType: ContactTypes) {
    switch (contactType) {
      case ContactTypes.CLIENT:
        return this.busyRoutes.includes(ApiRoutes.CLIENTS);

      case ContactTypes.FEE_EARNER:
        return this.busyRoutes.includes(ApiRoutes.FEE_EARNERS);

      case ContactTypes.COURT:
        return this.busyRoutes.includes(ApiRoutes.COURTS);
    }
  }
}

export default ApiStore;
