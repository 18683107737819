import React, {useMemo} from 'react';
import {Dayjs} from 'dayjs';

type DatepickerCalendarYearProps = {
  currentDate: Dayjs;
  onChange: (date: Dayjs) => void;
  onFocus: (e: React.FocusEvent<HTMLButtonElement>) => void;
}

const DatepickerCalendarYear = ({
                                  currentDate,
                                  onChange,
                                  onFocus
                                } : DatepickerCalendarYearProps) => {
  const months = useMemo(() => {
    const monthNodes : React.ReactNode[] = [];
    const yearStart = currentDate.startOf('year');

    for (let i = 0; i < 12; i++) {
      const month = yearStart.add(i, 'month');

      monthNodes.push(
        <button type='button'
                id={ `dpms_${month.format('MM')}` }
                key={ `dpms_${month.format('MMMM-YYYY')}` }
                onClick={ () => onChange(month) }
                onFocus={ onFocus }
                aria-label={ month.format('MMMM YYYY') }
                className='date-picker-calendar__month-select'>
          { month.format('MMMM') }
        </button>
      )
    }

    return monthNodes;
  }, [currentDate]);

  return (
    <div className='date-picker-calendar__year flex flex-row flex-wrap'>
      { months }
    </div>
  )
}

export default DatepickerCalendarYear;
