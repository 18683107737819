import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';

import Store, {DatabaseItem} from '@store/RootStore';
import {Client} from '@store/ClientStore';
import {
  ActivityCode, ActivityType, CostingData, Counsel, ExpertCode, ExpertGroup,
  LegalCase,
  LegalCaseActivity,
  LegalCaseQuestionnaire,
  QuestionnaireQuestion,
  TimedDisbursement
} from '@store/LegalCaseStore';
import {
  FeeEarner,
  FeeEarnerParams,
  FeeEarnerRate,
  RoutineCommunication
} from '@store/FeeEarnerStore';

import {LocalKeys} from '@app';
import {LoginFormData} from '@component/login/Login';
import SystemNotification, {NotificationTypes} from '@system-notification';
import {Court, CourtType} from '../store/CourtStore';
import {PagedData, Pagination} from '../store/PagedDataStore';
import {Company} from '../store/CompanyStore';
import {User} from '../store/UserStore';
import {LegalDocument} from '../store/DocumentStore';
import Costing from '../component/cases/costing/Costing';

const baseURL = 'https://draftapi.rwd.group';

export enum ApiRoutes {
  LOGIN = '/login',
  LOGOUT = '/logout',
  SELF = '/self',
  USERS = '/users',
  DRAFTSMAN_COMPANY = '/draftsmanCompanies',
  CASES = '/legalCases',
  CASE_TYPES = '/legalCaseTypes',
  DOCUMENT_TYPES = '/documentTypes',
  REFERENCE_NUMBER = '/referenceNumber',
  CLIENTS = '/clients',
  FEE_EARNERS = '/feeEarners',
  COURTS = '/courts',
  COURT_TYPES = 'courtTypes',
  SEARCH = '/search',
  QUESTIONNAIRE = '/questionnaireQuestions',
  ACTIVITY_TYPES = '/activityTypes',
  ACTIVITY_CODES = '/activityCodes',
  EXPERT_GROUPS = '/expertGroups',
  EXPERT_CODES = '/expertCodes'
}

export interface Params extends Pagination {
  search?: string;
  fields?: string;
  sort?: string;
  archived?: boolean;
  id?: number;
  legalCaseId?: number;
  legalDocumentId?: number;
  documentTypeId?: number;
  activityTypeId?: number;
  clientId?: number;
  feeEarnerId?: number;
  courtId?: number;
  expertGroupId?: number;
  documentUrl?: string;
  timedDisbursement?: TimedDisbursement;
  preferred?: boolean;
  ratesOnly?: boolean;
  ignoreWarning?: boolean;
}

export const instance = axios.create({
  withCredentials: true,
  baseURL,
  timeout: 120000,
  params: {}
});

/** For testing loading icons and effects **/
const stall = () => new Promise((resolve) => {
  setTimeout(() => { resolve(true) }, 5000);
});

/** Set to null to stop testing **/
const stallRoute: ApiRoutes | null = null;

instance.interceptors.request.use(
  async (axiosConfig: AxiosRequestConfig) => {
    const { apiStore } = Store;
    apiStore.setBusy(axiosConfig.url);
    if (stallRoute && axiosConfig.url.includes(stallRoute)) await stall();
    return Promise.resolve(axiosConfig);
  },
  error => Promise.reject(error)
)

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    const { apiStore } = Store;
    apiStore.removeBusy(response.config.url);
    return Promise.resolve(response);
  },
  (error: AxiosError) => Promise.reject(error)
)

export const catchHandler = (error: AxiosError) : null => {
  console.error('API ERROR:', error);
  const { apiStore } = Store;
  apiStore.removeBusy(error.response?.config.url);

  if (error.message === 'canceled') return;

  if (error.response?.status === 401) {
    Store.userStore.unsetData();
    localStorage.removeItem(LocalKeys.CD_LOCAL_USER);
    return null;
  }

  if (error.response?.data) {
    SystemNotification.showMessage(
      error.response.data?.error ||
      'A network error has occurred',
      NotificationTypes.ERROR
    );
    return null;
  }

  SystemNotification.showMessage('A network error has occurred', NotificationTypes.ERROR);
  return null;
}

// TODO look at tidying this all up, maybe adding a wrapper for the abort signal
//  Might not be necessary though, this isn't massively untidy

export type ApiReturnType<T> = Promise<AxiosResponse<T, void>>;
export type ApiPagedGet<T> = (params?: Params, signal?: AbortSignal) => ApiReturnType<PagedData<T>>;
export type ApiObjectGet<T> = (params?: Params, signal?: AbortSignal) => ApiReturnType<T>;

const API = {
  login: (formData: LoginFormData) => instance.post(ApiRoutes.LOGIN, formData),
  logout: () => instance.get(ApiRoutes.LOGOUT),

  getSelf: () : ApiReturnType<User> =>
    instance.get(ApiRoutes.USERS + ApiRoutes.SELF),

  getSelfCompany: () : ApiReturnType<Company> =>
    instance.get(ApiRoutes.DRAFTSMAN_COMPANY + ApiRoutes.SELF),

  postReferenceNumber: (clientId: number, signal?: AbortSignal) =>
    instance.post(ApiRoutes.REFERENCE_NUMBER, { clientId }, { signal }),

  getLegalCaseTypes: (signal?: AbortSignal) : ApiReturnType<DatabaseItem[]> =>
    instance.get(ApiRoutes.CASE_TYPES, { signal }),

  getLegalCases: (params?: Params, signal?: AbortSignal) : ApiReturnType<PagedData<LegalCase>> =>
    instance.get(ApiRoutes.CASES, { params, signal }),

  getLegalCase: (params: Params, signal?: AbortSignal) : ApiReturnType<LegalCase> =>
    instance.get(`${ApiRoutes.CASES}/${params.legalCaseId}`, { signal }),

  postLegalCase: (legalCase: LegalCase, signal?: AbortSignal) : ApiReturnType<LegalCase> =>
    instance.post(ApiRoutes.CASES, legalCase, { signal }),

  patchLegalCase: (legalCase: LegalCase, signal?: AbortSignal) : ApiReturnType<LegalCase> =>
    instance.patch(`${ApiRoutes.CASES}/${legalCase.id}`, legalCase, { signal }),

  getLegalCaseQuestionnaire: (params: Params, signal?: AbortSignal) : ApiReturnType<LegalCaseQuestionnaire> =>
    instance.get(`${ApiRoutes.CASES}/${params.legalCaseId}/questionnaire`, { signal }),

  patchLegalCaseQuestionnaire: (legalCaseQuestionnaire: LegalCaseQuestionnaire, params?: Params, signal?: AbortSignal) : ApiReturnType<LegalCaseQuestionnaire> =>
    instance.patch(`${ApiRoutes.CASES}/${params.legalCaseId}/questionnaire`, legalCaseQuestionnaire, { signal }),

  getLegalDocuments: (params: Params, signal?: AbortSignal) : ApiReturnType<PagedData<LegalDocument>> =>
    instance.get(`${ApiRoutes.CASES}/${params.legalCaseId}/legalDocuments`, { signal }),

  getLegalDocument: (params: Params, signal?: AbortSignal) : ApiReturnType<LegalDocument> =>
    instance.get(`${params.documentUrl}/${params.legalDocumentId}`, { signal }),

  getDocumentTypes: (params?: Params, signal?: AbortSignal) =>
    instance.get(ApiRoutes.DOCUMENT_TYPES, { params, signal }),

  createLegalDocument: (legalCaseId: number, documentTypeId: number, signal?: AbortSignal) : ApiReturnType<LegalDocument> =>
    instance.post(`${ApiRoutes.CASES}/${legalCaseId}/legalDocuments`, { documentTypeId }, { signal }),

  getClients: (params?: Params, signal?: AbortSignal) : ApiReturnType<PagedData<Client>> =>
    instance.get(ApiRoutes.CLIENTS, { params, signal }),

  getClient: (params: Params, signal?: AbortSignal) : ApiReturnType<Client> =>
    instance.get(ApiRoutes.CLIENTS + `/${params.id}`, { signal }),

  postClient: (client: Client, signal?: AbortSignal) : ApiReturnType<Client> =>
    instance.post(ApiRoutes.CLIENTS, client, { signal }),

  patchClient: (client: Client, signal?: AbortSignal) : ApiReturnType<Client> =>
    instance.patch(`${ApiRoutes.CLIENTS}/${client.id}`, client, { signal }),

  getCourtTypes: (signal?: AbortSignal) : ApiReturnType<CourtType[]> =>
    instance.get(ApiRoutes.COURT_TYPES, { signal }),

  getCourts: (params?: Params, signal?: AbortSignal) : ApiReturnType<PagedData<Court>> =>
    instance.get(ApiRoutes.COURTS, { params, signal }),

  getCourt: (params: Params, signal?: AbortSignal) : ApiReturnType<Court> =>
    instance.get(ApiRoutes.COURTS + `/${params.id}`, { signal }),

  postCourt: (court: Court, signal?: AbortSignal) : ApiReturnType<Court> =>
    instance.post(ApiRoutes.COURTS, court, { signal }),

  patchCourt: (court: Court, signal?: AbortSignal) : ApiReturnType<Court> =>
    instance.patch(ApiRoutes.COURTS + `/${court.id}`, court, { signal }),

  getFeeEarners: (params?: FeeEarnerParams, signal?: AbortSignal) : ApiReturnType<PagedData<FeeEarner>> =>
    instance.get(ApiRoutes.FEE_EARNERS, { params, signal }),

  getFeeEarner: (params: Params, signal?: AbortSignal) : ApiReturnType<FeeEarner> =>
    instance.get(ApiRoutes.FEE_EARNERS + `/${params.id}`, { signal }),

  postFeeEarner: (feeEarner: FeeEarner, signal?: AbortSignal) : ApiReturnType<FeeEarner> =>
    instance.post(ApiRoutes.FEE_EARNERS, feeEarner, { signal }),

  patchFeeEarner: (feeEarner: FeeEarner, params?: Params, signal?: AbortSignal) : ApiReturnType<FeeEarner> =>
    instance.patch(`${ApiRoutes.FEE_EARNERS}/${feeEarner.id}`, feeEarner, { signal }),

  patchPreference: (params?: Params, signal?: AbortSignal) =>
    instance.patch(ApiRoutes.USERS + ApiRoutes.SELF + '/preferences', params, { signal }),

  getQuestionnaireQuestions: (sectionNumber: number, previousAnswer: string, signal?: AbortSignal) : ApiReturnType<QuestionnaireQuestion[]> =>
    instance.get(ApiRoutes.QUESTIONNAIRE, {
      params: {
        sectionNumber,
        previousAnswer
      },
      signal
    }),

  getLegalCaseFeeEarners: (params: Params, signal?: AbortSignal) : ApiReturnType<PagedData<FeeEarner>> =>
    instance.get(`${ApiRoutes.CASES}/${params.legalCaseId}/feeEarners`, { params, signal }),

  getFeeEarnerRates: (params: Params, signal?: AbortSignal) : ApiReturnType<FeeEarnerRate[]> =>
    instance.get(`${ApiRoutes.CASES}/${params.legalCaseId}/feeEarnerRates`, { signal }),

  postFeeEarnerRate: (params: Params, signal?: AbortSignal) : ApiReturnType<FeeEarnerRate> =>
    instance.post(`${ApiRoutes.CASES}/${params.legalCaseId}/feeEarnerRates`, {}, { signal }),

  patchFeeEarnerRate: (feeEarnerRate: FeeEarnerRate, params: Params, signal?: AbortSignal) : ApiReturnType<FeeEarnerRate | FeeEarnerRate[]> =>
    instance.patch(`${ApiRoutes.CASES}/${params.legalCaseId}/feeEarnerRates/${feeEarnerRate.id}`, feeEarnerRate, { signal }),

  deleteFeeEarnerRate: (feeEarnerRate: FeeEarnerRate, params: Params, signal?: AbortSignal) : ApiReturnType<void> =>
    instance.delete(`${ApiRoutes.CASES}/${params.legalCaseId}/feeEarnerRates/${feeEarnerRate.id}`, { params, signal }),

  patchRoutineCommunication: (routineCommunication: RoutineCommunication, params: Params, signal?: AbortSignal) : ApiReturnType<FeeEarnerRate> =>
    instance.patch(`${ApiRoutes.CASES}/${params.legalCaseId}/routineCommunications/${routineCommunication.id}`, routineCommunication, { signal }),

  getActivityTypes: (params: Params, signal?: AbortSignal) : ApiReturnType<ActivityType[]> =>
    instance.get(ApiRoutes.ACTIVITY_TYPES, { params, signal }),

  postActivityType: (activityType: ActivityType, params?: Params, signal?: AbortSignal) : ApiReturnType<ActivityType> =>
    instance.post(ApiRoutes.ACTIVITY_TYPES, activityType, { params, signal }),

  deleteActivityType: (activityType: ActivityType, params?: Params, signal?: AbortSignal) : ApiReturnType<ActivityType> =>
    instance.delete(`${ApiRoutes.ACTIVITY_TYPES}/${activityType.id}`, { params, signal }),

  getActivityCodes: (params: Params, signal?: AbortSignal) : ApiReturnType<ActivityCode[]> =>
    instance.get(ApiRoutes.ACTIVITY_CODES, { params, signal }),

  getCounsels: (params: Params, signal?: AbortSignal) : ApiReturnType<Counsel[]> =>
    instance.get(`${ApiRoutes.CASES}/${params.legalCaseId}/counsels`, { params, signal }),

  getExpertGroups: (params: Params, signal?: AbortSignal) : ApiReturnType<ExpertGroup[]> =>
    instance.get(ApiRoutes.EXPERT_GROUPS, { params, signal }),

  getExpertCodes: (params: Params, signal?: AbortSignal) : ApiReturnType<ExpertCode[]> =>
    instance.get(ApiRoutes.EXPERT_CODES, { params, signal }),

  getActivities: (params: Params, signal?: AbortSignal) : ApiReturnType<LegalCaseActivity[]> =>
    instance.get(`${ApiRoutes.CASES}/${params.legalCaseId}/activities`,{ params, signal }),

  postActivity: (activity: LegalCaseActivity, params: Params, signal?: AbortSignal) : ApiReturnType<LegalCaseActivity> =>
    instance.post(`${ApiRoutes.CASES}/${params.legalCaseId}/activities`, activity, { signal }),

  patchActivity: (activity: LegalCaseActivity, params: Params, signal?: AbortSignal) : ApiReturnType<LegalCaseActivity> =>
    instance.patch(`${ApiRoutes.CASES}/${params.legalCaseId}/activities/${activity.id}`, activity, { signal }),

  deleteActivity: (activity: LegalCaseActivity, params: Params, signal?: AbortSignal) : ApiReturnType<void> =>
    instance.delete(`${ApiRoutes.CASES}/${params.legalCaseId}/activities/${activity.id}`, { signal }),

  getCosting: (params: Params, signal?: AbortSignal) : ApiReturnType<CostingData> =>
    instance.get(`${ApiRoutes.CASES}/${params.legalCaseId}/costings`, { signal }),

  search: (search: string, signal?: AbortSignal) => instance.post(ApiRoutes.SEARCH, { search }, { signal })
};

export default API;
