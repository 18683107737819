import React from 'react';
import {observer} from 'mobx-react-lite';

import useStore from '@hook/useStore';
import {UiTopLayerAlignment, UiTopLayerElement} from '@store/UiTopLayerStore';

const UiTopLayer = observer(() => {
  const { uiTopLayerStore } = useStore();

  const getStyle = (element : UiTopLayerElement) : React.CSSProperties => {
    const bounds = element.parentElement.getBoundingClientRect();
    const hDirection = element.alignment === UiTopLayerAlignment.LEFT ?
      bounds.left > innerWidth * 0.75 ? 'right' : 'left' : 'right';

    const hValue = hDirection === 'left' ? bounds.left : innerWidth - bounds.right;

    const vDirection = bounds.top > innerHeight * 0.75 ? 'bottom' : 'top';
    const vValue = vDirection === 'top' ? bounds.bottom : innerHeight - bounds.top;

    // TODO look at height of element when opening from bottom vValue

    const returnObject = {
      [hDirection] : hValue,
      [vDirection] : vValue
    };

    if (element.alignment === UiTopLayerAlignment.FIT) {
      returnObject.left = bounds.left;
      returnObject.right = innerWidth - bounds.right;
    }

    if (element.alignment !== UiTopLayerAlignment.FIT) {
      returnObject.width = element.width;
    }

    return returnObject;
  }

  return (
    <div className='ui-top-layer'>
      {
        uiTopLayerStore.elements.map(element => (
          <div className='ui-top-layer-element'
               data-uuid={ element.uuid }
               style={ getStyle(element) }
               key={ element.uuid }>
            { element.children }
          </div>
        ))
      }
    </div>
  )
})

export default UiTopLayer;