import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {AxiosResponse} from 'axios';

import API, {ApiRoutes, catchHandler} from '@api';
import {Client} from '@store/ClientStore';
import {FeeEarner} from '@store/FeeEarnerStore';
import {LegalCase} from '@store/LegalCaseStore';
import {ModalContext} from '@context/ModalContext';
import useStore from '@hook/useStore';
import useKeyboardShortcut from '@hook/useKeyboardShortcut';
import useAbortController from '@hook/useAbortController';

import TextInput from '@component/input/TextInput';
import Button from '@component/button/Button';
import LoadingCircle from '../loading/LoadingCircle';
import ClientDropDown from '../drop-down/common-drop-downs/ClientDropDown';
import FieldLengths from '../../FieldLengths';

const NewCaseForm = observer(() => {
  const navigate = useNavigate();
  const { apiStore, modalStore } = useStore();
  const [formData, setFormData] = useState<LegalCase>({});
  const [busy, setBusy] = useState<boolean>(false);
  const [refBusy, setRefBusy] = useState<boolean>(false);
  const [cdRef, setCdRef] = useState<string>('##-##-#-#######');
  const [checkInputs, setCheckInputs] = useState<boolean>(null);
  const modalContext = useContext(ModalContext);
  const abortController = useAbortController();

  useEffect(() => {
    setBusy(apiStore.checkBusy(ApiRoutes.CASES));
    setRefBusy(apiStore.checkBusy(ApiRoutes.REFERENCE_NUMBER));
  }, [apiStore.busyRoutes.length]);

  const changeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.currentTarget.name]: e.currentTarget.value
    });
  }

  const changeClientHandler = (client: Client) => {
    setFormData({
      ... formData,
      client,
      feeEarners: []
    });

    API.postReferenceNumber(client.id, abortController.getNewSignal())
      .then((response: AxiosResponse<LegalCase>) => {
        setCdRef(response.data.cdReference);
      })
      .catch(catchHandler);
  }

  const submitHandler = () => {
    setCheckInputs(true);
    setTimeout(() => setCheckInputs(false), 50);

    if (!formData.clientReference || formData.clientReference.trim().length <= 0) {
      document.getElementById('nf_client-ref')?.focus();
      return;
    }

    if (!formData.client) {
      document.getElementById('dd_client-drop-down')?.focus();
      return;
    }

    if (!formData.caseTitle || formData.caseTitle.trim().length <= 0) {
      document.getElementById('nf_case-title')?.focus();
      return;
    }

    API.postLegalCase({
      clientReference: formData.clientReference,
      cdReference: cdRef,
      caseTitle: formData.caseTitle,
      clientId: formData.client.id,
      feeEarnerIds: formData.feeEarners.map((feeEarner: FeeEarner) => feeEarner.id)
    }, abortController.getNewSignal())
      .then(response => {
        modalStore.removeModal(modalContext.data.uuid);
        navigate(`/cases/${response.data.id}`)
      })
      .catch(catchHandler);
  }

  useKeyboardShortcut('Enter', submitHandler, [formData]);

  return (
    <div data-busy={ busy }
         role='form'
         className='form'>
      <TextInput
        id={ 'nf_client-ref' }
        name='clientReference'
        label='Enter client case reference'
        value={ formData.clientReference ?? '' }
        maxLength={ FieldLengths.CLIENT_REFERENCE }
        onChange={ changeHandler }
        autoFocus={ true }
        required={ true }
        forceCheckValid={ checkInputs }
      />
      <ClientDropDown
        value={ formData?.client }
        onChange={ changeClientHandler }
        valid={ checkInputs !== null ? typeof formData.client !== 'undefined' : null }
      />
      <div className='cdRef flex flex-row flex-align-center'>
        <span className='label'>CostDrafter Reference:</span>
        <span className='value'>{ cdRef }</span>
        {
          refBusy &&
          <LoadingCircle />
        }
      </div>
      <TextInput
        id='nf_case-title'
        name='caseTitle'
        label='Case Title'
        value={ formData.caseTitle ?? '' }
        maxLength={ FieldLengths.CASE_TITLE }
        onChange={ changeHandler }
        required={ true }
        forceCheckValid={ checkInputs }
      />
      <div className='button-row flex flex-row flex-center'>
        <Button onClick={ submitHandler }>
          Create Case
        </Button>
      </div>
    </div>
  )
});

export default NewCaseForm;

