import React, {useEffect, useState} from 'react';

export default () => {
  const [abortController, setAbortController] = useState<AbortController>(new AbortController());

  useEffect(() => {
    return () => abortController.abort();
  }, []);

  return {
    getNewSignal: () => {
      const controller = new AbortController();
      setAbortController(controller);
      return controller.signal
    },
    abort: () => abortController.abort()
  }
}