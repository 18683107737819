import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';

import useStore from '@hook/useStore';
import {User} from '@store/UserStore';

type AuthenticationCheckProps = {
  onFailedAuth: () => void;
}

const AuthenticationCheck = observer(({
                                        onFailedAuth
                                      } : AuthenticationCheckProps) => {
  const [prevState, setPrevState] = useState<User>({});
  const { userStore } = useStore();

  useEffect(() => {
    if (prevState.id === userStore.data.id) return;

    if (userStore.data.id === null) {
      onFailedAuth();
    }

    setPrevState(userStore.data);
  }, [ userStore.data ]);

  return null;
});

export default AuthenticationCheck;
