import React from 'react';
import { Link } from 'react-router-dom';

import { LoginFormData } from './Login';
import TextInput from '@component/input/TextInput';
import Button from '@component/button/Button';
import CheckBox from '../input/CheckBox';

type LoginFormProps = {
  formData: LoginFormData;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onToggleRemember: (value: boolean) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const LoginForm = ({
                     formData,
                     onChange,
                     onToggleRemember,
                     onSubmit
                   } : LoginFormProps) => {
  return (
    <form className='login-form flex flex-column flex-center'
          onSubmit={ onSubmit }>
      <img src='/img/svg/logo-smoke.svg'
           width={ 220 }
           height={ 30 }
           alt='CostDrafter' />
      <TextInput
        id='log_email'
        type='email'
        name='emailAddress'
        label='Email Address'
        value={ formData.emailAddress }
        onChange={ onChange }
        autoFocus={ true }
        autoComplete="email"
      />
      <TextInput
        id='log_password'
        type='password'
        name='password'
        label='Password'
        value={ formData.password }
        onChange={ onChange }
        autoComplete="current-password"
      />
      <CheckBox
        id='log_remember'
        name='rememberMe'
        label='Remember Me'
        checked={ formData.rememberMe }
        onChange={ onToggleRemember }
      />
      <div className='animate-in'>
        <Button
          type='submit'
          label='Login'
          disabled={
            formData.emailAddress.length <= 0 ||
            formData.password.length <= 0
          }>
          Login
        </Button>
      </div>
      <div className='login-form__links flex flex-column flex-center'>
        <Link to='/forgot-password/'
              title='Forgotten password?'
              className='ui-hover ui-hover--light'>
          Forgotten password?
        </Link>
        <Link to='/contact-support/'
              title='Contact Support'
              className='ui-hover ui-hover--light'>
          Contact Support
        </Link>
      </div>
    </form>
  )
}

export default LoginForm;
