import React, {ForwardedRef, forwardRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';

import useInputState, {BaseInputProps} from '@hook/useInputState';
import '@styles/input/textarea.scss';

const Textarea = forwardRef((props : BaseInputProps,
                             ref : ForwardedRef<HTMLTextAreaElement>) => {
  const [inputId] = useState<string>(props.id || uuidv4());
  const inputState = useInputState({
    props,
    inputId
  })

  return (
    <div className='textarea'
         data-error={ inputState.error }
         data-filled={ props.value.length > 0 }
         data-has-focus={ inputState.focus }>
      <label htmlFor={ inputId }>{ props.label || props.name }</label>
      <textarea
        id={ inputId }
        name={ props.name }
        ref={ ref }
        value={ props.value }
        onChange={ props.onChange }
        onKeyUp={ props.onKeyUp }
        onFocus={ inputState.focusHandler }
        onBlur={ inputState.blurHandler }
        autoFocus={ props.autoFocus }
      />
      {
        inputState.error &&
        <div className='error'>Please fill in this field</div>
      }
      {
        inputState.error &&
        <img src='/img/svg/error-red.svg' alt='Error' />
      }
    </div>
  )
});

export default Textarea;
