import React from 'react';

type BackButtonProps = {
  onClick?: () => void;
}

const BackButton = ({ onClick } : BackButtonProps) => {
  return (
    <button type='button'
            className='back-button flex flex-row flex-align-center'
            onClick={ onClick }>
      <div className='img'>
        <img src='/img/svg/arrow-left.svg' alt='Back' />
      </div>
      <span>Go back</span>
    </button>
  )
}

export default BackButton;
