import React from 'react';
import {Dayjs} from 'dayjs';

import {CalendarViews} from './DatePickerCalendar';

type DatePickerCalendarHeadProps = {
  view: CalendarViews;
  currentDate: Dayjs;
  onChangeView: (view: CalendarViews) => void;
  onPrevious: () => void;
  onNext: () => void;
}

const DatePickerCalendarHead = ({
                                  view,
                                  currentDate,
                                  onChangeView,
                                  onPrevious,
                                  onNext
                                } : DatePickerCalendarHeadProps) => {
  const clickHandler = () => {
    onChangeView(CalendarViews.YEAR);
  }

  return (
    <div className='date-picker-calendar__head flex flex-row flex-align-center'>
      <button type='button'
              onClick={ onPrevious }
              aria-label='Previous'>
        <img src='/img/svg/arrow-left.svg' alt='Previous' />
      </button>
      {
        view === CalendarViews.MONTH ?
          <button type='button'
                  className='title'
                  aria-label='View by year'
                  onClick={ clickHandler }>
            { currentDate.format('MMM YYYY') }
          </button>
          :
          <span className='title'>{ currentDate.year() }</span>
      }
      <button type='button'
              onClick={ onNext }
              aria-label='Next'>
        <img src='/img/svg/arrow-right.svg' alt='Next' />
      </button>
    </div>
  )
}

export default DatePickerCalendarHead;
