import * as React from 'react';
import {ReactNode, useContext, useEffect, useRef, useState} from 'react';

import {Params} from '@api';
import {DropDownContext} from '@context/DropDownContext';
import {PagedData, Pagination} from '@store/PagedDataStore';

import TextInput from '@component/input/TextInput';
import WithTooltip from '@component/WithTooltip';
import DatabaseItemDropDownList from './DatabaseItemDropDownList';

import Events from '@events';
import {DatabaseItem} from '@store/RootStore';
import {toJS} from 'mobx';

type DatabaseItemDropDownProps<T> = {
  label: string;
  getData?: (params: Params) => Promise<PagedData<DatabaseItem<T>>>,
  staticData?: DatabaseItem<T>[];
  onChange: (value: DatabaseItem<T>) => void;
  displayItemAs?: (t: DatabaseItem<T>) => ReactNode;
  value: DatabaseItem<T>;
  onOpenAdd?: () => void;
  keyGenerator?: (t: DatabaseItem<T>) => string;
  selectedCheck?: (value: DatabaseItem<T>, t: DatabaseItem<T>) => boolean;
  searchFunction?: (search: string, item: DatabaseItem<T>) => boolean;
};

export const DatabaseItemDropDown = <T, >({
                                            label,
                                            staticData,
                                            getData,
                                            onChange,
                                            displayItemAs,
                                            value,
                                            onOpenAdd,
                                            keyGenerator,
                                            selectedCheck,
                                            searchFunction
                                          } : DatabaseItemDropDownProps<T>) => {
  const limit = 20; // Maybe make this a prop
  const dropDownContext = useContext(DropDownContext);
  const [search, setSearch] = useState<string>("");
  const [pagination, setPagination] = useState<Pagination>(null);
  const [data, setData] = useState<DatabaseItem<T>[]>(staticData ?? []);
  const [busy, setBusy] = useState<boolean>(false);
  const searchTimeoutRef = useRef<NodeJS.Timeout>(null);

  useEffect(() => {
    if (getData) {
      setBusy(true);

      getData({
        page: 1,
        limit
      })
        .then(newData => {
          if (newData?.data) {
            setBusy(false);
            setPagination(newData.pagination);
            setData(newData.data);
          }
        });
    }

    window.addEventListener(Events.KEY_UP, keyHandler);
    return () => clearTimeout(searchTimeoutRef.current);
  }, []);

  useEffect(() => {
    window.addEventListener(Events.KEY_UP, keyHandler);
    return () => window.removeEventListener(Events.KEY_UP, keyHandler);
  }, [data]);

  const keyHandler = (e: KeyboardEvent) => {
    const activeElement = document.activeElement as HTMLElement;

    if (activeElement.tagName === 'INPUT' &&
      e.key === 'Enter' &&
      search.length > 0) {
      onChange(data[0]);
      dropDownContext.onClose();
    }

    if (e.altKey && e.key.toLowerCase() === 'n') {
      openAddForm();
    }
  }

  const changeSearchHandler = (e: React.FormEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    setSearch(value);

    // If we have dynamic data and are running an API query
    if (getData) {
      clearTimeout(searchTimeoutRef.current);

      if (value.length <= 0) {
        setData([]);
        setBusy(true);
        getData({
          page: 1,
          limit
        }).then((newData: PagedData<T>) => finishGet(newData, true));
        return;
      }

      setData([]);
      setBusy(true);

      searchTimeoutRef.current = setTimeout(() => {
        setData([]);
        setBusy(true);
        getData({
          page: 1,
          limit,
          search: value
        }).then((newData: PagedData<T>) => finishGet(newData, true));
      }, 350);
    } else {
      // If we're using a smaller, more static list that doesn't require API search functions
      if (value.length <= 0) {
        setData(staticData);
      } else {
        setData(staticData.filter(item => searchFunction ? searchFunction(value, item) : defaultSearchFunction(value, item)));
      }
    }
  }

  console.log(toJS(data));

  const getMoreHandler = () => {
    if (pagination.page + 1 > pagination?.totalPages) return;

    const pageParams = {
      page: pagination.page + 1,
      limit,
      search
    }

    setBusy(true);
    getData(pageParams).then(finishGet);
  }

  const finishGet = (newData: PagedData<T>, replace?: boolean) => {
    setBusy(false);
    setPagination(newData.pagination);

    if (replace) {
      setData(newData.data);
    } else {
      setData([
        ...data,
        ...newData.data
      ]);
    }
  }

  const openAddForm = () => {
    onOpenAdd();
    dropDownContext.onClose();
  }

  const selectHandler = (item: T) => {
    onChange(item);
    dropDownContext.onClose();
  }

  const defaultSearchFunction = (value: string, item: DatabaseItem<T>) => {
    return item.name.toLowerCase().includes(value.toLowerCase());
  }
  const defaultDisplayAs = (item: DatabaseItem<T>) => item.name;
  const defaultKeyGenerator = (item: DatabaseItem<T>) => `didd_list_item_${item?.id}`;
  const defaultSelectedCheck = (value: DatabaseItem<T>, item: DatabaseItem<T>) => item?.id === value?.id;

  return (
    <div className='database-item-drop-down'>
      <div className='database-item-drop-down__search'>
        <TextInput
          name='search'
          label='Search...'
          value={ search }
          onChange={ changeSearchHandler }
        />
      </div>
      <DatabaseItemDropDownList
        displayItemAs={ displayItemAs ?? defaultDisplayAs }
        value={ value }
        busy={ busy }
        data={ data }
        usingSearch={ search?.length > 0 }
        onSelect={ selectHandler }
        onGetMore={ getMoreHandler }
        keyGenerator={ keyGenerator ?? defaultKeyGenerator }
        selectedCheck={ selectedCheck ?? defaultSelectedCheck }
      />
      {
        onOpenAdd &&
        <div className='database-item-drop-down__add-button flex flex-row'>
          <WithTooltip
            shortcutReminder='Alt+N'
            tooltip={`Add a new ${label} to your list`}>
            <button type='button'
                    onClick={ openAddForm }
                    data-close-drop={ true }
                    aria-label={ `Add new ${label}` }
                    className='flex flex-row flex-align-center'>
              <span>Add New { label }</span>
              <div className='img'>
                <img src='/img/svg/add.svg' alt='Add' />
              </div>
            </button>
          </WithTooltip>
        </div>
      }
    </div>
  )
}

export default DatabaseItemDropDown;
