export enum NotificationTypes {
  NOTIFICATION = 'notification',
  ERROR= 'error'
}

class SystemNotification {
  static TIMEOUT = 3000;
  notificationTimeout: NodeJS.Timeout;
  notificationDiv: HTMLDivElement;

  constructor() {
    this.notificationDiv = document.createElement('div');
    this.notificationDiv.className = 'sys-notification flex flex-row flex-center';
  }

  showMessage(message: string, type?: NotificationTypes) {
    this.notificationDiv.innerHTML = `<p>${message}</p>`;

    if (type) {
      this.notificationDiv.setAttribute('data-type', type.toString());
      this.notificationDiv.insertAdjacentHTML(
        'afterbegin',
        `<img src="/img/svg/${type}.svg" alt="${type}" />`
      );
    }

    if (!document.body.contains(this.notificationDiv)) {
      document.body.appendChild(this.notificationDiv);
    }

    clearTimeout(this.notificationTimeout);
    this.notificationTimeout = setTimeout(() => {
      this.hideMessage();
    }, SystemNotification.TIMEOUT);
  }

  hideMessage() {
    document.body.removeChild(this.notificationDiv);
    this.notificationDiv.setAttribute('data-type', null);
  }
}

const notification = new SystemNotification();
export default notification;
