import React, {useEffect, useRef, useState} from 'react';
import {DatabaseItem} from '@store/RootStore';
import {CommonItem} from './DropDownItemDisplays';

import Events from '@events';

type DropDownSimpleListProps<T> = {
  items: DatabaseItem<T>[];
  onSelect: (item: DatabaseItem<T>) => void;
  getItemDisplay?: (item: DatabaseItem<T>) => string;
}

const DropDownSimpleList = <T, >({
                                   items,
                                   onSelect,
                                   getItemDisplay
                                 } : DropDownSimpleListProps<T>) => {
  const [searchString, setSearchString] = useState<string>('');
  const containerRef = useRef<HTMLDivElement>(null);
  const searchTimeoutRef = useRef<NodeJS.Timeout>(null);

  useEffect(() => {
    return () => clearTimeout(searchTimeoutRef.current);
  })

  useEffect(() => {
    document.addEventListener(Events.KEY_DOWN, keyDownHandler);

    if (searchString.length > 0) {
      const itemElArray = containerRef.current?.querySelectorAll('.drop-down-common-item');
      const re = new RegExp(`^${searchString}`);

      for (let i = 0; i < itemElArray.length; i++) {
        const target = itemElArray[i] as HTMLButtonElement;
        if (target.innerHTML.toLowerCase().match(re)) {
          target.focus();
        }
      }
    }

    return () => document.removeEventListener(Events.KEY_DOWN, keyDownHandler);
  }, [searchString]);

  const keyDownHandler = (e: KeyboardEvent) => {
    clearTimeout(searchTimeoutRef.current);

    if (e.key.length === 1) {
      setSearchString(prevState => prevState + e.key);
    }

    searchTimeoutRef.current = setTimeout(() => {
      setSearchString('');
    }, 100);
  }

  return (
    <div
      ref={ containerRef }
      className="drop-down-simple-list flex flex-column">
      {
        items.map(item => (
          <CommonItem
            key={ item.id ?? item.toString() }
            label={ getItemDisplay ? getItemDisplay(item) : item.name }
            onClick={ () => onSelect(item) }
          />
        ))
      }
    </div>
  )
}

export default DropDownSimpleList;