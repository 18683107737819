import React, {useEffect, useRef, useState} from 'react';
import FocusTrap from 'focus-trap-react';

import {ModalContext, ModalContextData} from '@context/ModalContext';
import useStore from '@hook/useStore';

import Events from '@events';
import '@styles/modal.scss';
import {ModalId} from '../store/ModalStore';
import BackButton from './button/BackButton';

export enum ModalSizes {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  FULL = 'fl'
}

export interface ModalProps {
  title?: React.ReactNode | string;
  children?: React.ReactNode;
  size?: ModalSizes;
  className?: string;
  beforeClose?: (uuid: string) => boolean;
  onClose?: () => void;
  id?: ModalId;
  uuid?: string;
  backButton?: boolean;
  returnFocus?: boolean;
}

const Modal = ({
                 title,
                 children,
                 className = '',
                 beforeClose,
                 onClose,
                 size = ModalSizes.SMALL,
                 uuid,
                 backButton,
                 returnFocus = true
               } : ModalProps) => {
  const { modalStore } = useStore();
  const [contextData, setContextData] = useState<ModalContextData>({
    uuid
  });
  const [closing, setClosing] = useState<boolean>(false);
  const [focusTrapActive, setFocusTrapActive] = useState<boolean>(true);
  const closeTimeout = useRef<NodeJS.Timeout>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const windowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    addEventListener(Events.POP_STATE, popHandler);

    return () => {
      removeEventListener(Events.POP_STATE, popHandler);
    }
  }, []);

  useEffect(() => {
    setContextData({
      ... contextData,
      overlay: overlayRef.current,
      window: windowRef.current
    });
  }, [windowRef.current, overlayRef.current]);

  const popHandler = () => {
    if (modalStore.modals[modalStore.modals.length - 1].uuid === uuid) {
      backHandler();
    }
  }

  const closeModal = () => {
    setClosing(true);

    clearTimeout(closeTimeout.current);
    closeTimeout.current = setTimeout(() => {
      if (onClose) onClose()
      modalStore.removeModal(uuid);
    }, 200);
  }

  const backHandler = () => {
    if (!beforeClose) {
      closeModal();
    }

    if (!closing && beforeClose(uuid) === true) {
      closeModal();
    }

    return false;
  }

  const setData = (data: ModalContextData) => {
    setContextData({
      ... contextData,
      ...data
    });
  }

  const outsideClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target as HTMLDivElement === overlayRef.current) {
      backHandler();
    }
  }

  return (
    <ModalContext.Provider value={{ data: contextData, setData }}>
      <div className={`modal ${className}`}
           id={ uuid }
           data-closing={ closing }
           onClick={ outsideClickHandler }
           ref={ overlayRef }>
        <FocusTrap
          focusTrapOptions={{
            returnFocusOnDeactivate: returnFocus,
            allowOutsideClick: true,
            escapeDeactivates: backHandler
          }}>
          <div className='modal__window'
               data-size={ size }
               ref={ windowRef }>
            {
              title &&
              <div className='modal__title'>
                {
                  backButton &&
                  <BackButton onClick={ backHandler } />
                }
                {
                  typeof title === 'string' ?
                    <span className='title'>{ title }</span>
                    :
                    title
                }
                <button type='button'
                        aria-label='Close'
                        className='close-button'
                        onClick={ backHandler }>
                  <img src='/img/svg/clear.svg' alt='Close' />
                </button>
              </div>
            }
            { children }
          </div>
        </FocusTrap>
      </div>
    </ModalContext.Provider>
  )
}

export default Modal;
