import React, {useMemo} from 'react';
import dayjs, {Dayjs} from 'dayjs';


type DatePickerCalendarMonthProps = {
  value: Dayjs;
  currentDate: Dayjs;
  onChange: (date: Dayjs) => void;
  onFocus: (e: React.FocusEvent<HTMLButtonElement>) => void;
  min?: Dayjs;
  max?: Dayjs;
  blockedDates?: (date: Dayjs) => boolean;
}

type DatePickerCalendarWeekProps = DatePickerCalendarMonthProps & {
  week: Dayjs;
}

const DatePickerCalendarWeek = ({
                                  value,
                                  currentDate,
                                  week,
                                  onChange,
                                  onFocus,
                                  min,
                                  max,
                                  blockedDates
                                } : DatePickerCalendarWeekProps) => {
  const getDisabled = (day: Dayjs) => {
    if (blockedDates) return blockedDates(day);

    return max && day.isAfter(max) ||
      min && day.isBefore(min) ||
      !day.isSame(currentDate, 'month')
  }

  const days = useMemo(() => {
    const dayNodes: React.ReactNode[] = [];
    const weekStart = week.startOf('week');

    for (let i = 0; i < 7; i++) {
      const day = weekStart.add(i, 'day');
      const disabled = getDisabled(day);

      dayNodes.push(
        <button className='date-picker-calendar__day'
                id={ `dpd_${day.format('DD')}` }
                key={ `dpd_${day.format('DD-MM-YYYY')}` }
                onClick={ () => onChange(day) }
                onFocus={ onFocus }
                data-today={ day.isSame(dayjs(), 'date') }
                aria-label={ day.format('DD/MM/YYYY') }
                aria-selected={ day.isSame(value, 'date') }
                disabled={ disabled }>
          { day.format('DD') }
        </button>
      )
    }

    return dayNodes;
  }, [currentDate]);

  return (
    <div className='date-picker-calendar__week flex flex-row flex-stretch'>
      { days }
    </div>
  )
}

const DatePickerCalendarMonth = ({
                                   value,
                                   currentDate,
                                   onChange,
                                   onFocus,
                                   min,
                                   max,
                                   blockedDates
                                 } : DatePickerCalendarMonthProps) => {
  const weeks = useMemo(() => {
    const weekNodes : React.ReactNode[] = [];
    const monthStart = currentDate.startOf('month');
    const firstDay = monthStart.day() !== 0 ?
      monthStart.subtract(monthStart.day(), 'day') : monthStart;
    const lastDay = currentDate.endOf('month');
    const diff = lastDay.diff(firstDay, 'd');
    const weeks = Math.floor(diff / 7) + 1;

    for (let i = 0; i < weeks; i++) {
      const week = currentDate.startOf('month').add(i, 'week');

      weekNodes.push(
        <DatePickerCalendarWeek
          key={ `dpw_${week.format('DD-MM-YYYY')}` }
          value={ value }
          week={ week }
          currentDate={ currentDate }
          onChange={ onChange }
          onFocus={ onFocus }
          min={ min }
          max={ max }
          blockedDates={ blockedDates }
        />
      )
    }

    return weekNodes;
  }, [currentDate]);

  return (
    <div className='date-picker-calendar__month'>
      { weeks }
    </div>
  )
}

export default DatePickerCalendarMonth;
