
import {DatabaseItem, RootStore} from './RootStore';
import {action, makeObservable, observable} from 'mobx';

export interface PagedData<T> {
  pagination: Pagination,
  data: T[];
}

export interface Pagination {
  page?: number;
  limit?: number;
  totalPages?: number;
  results?: number;
}

const emptyPagination: Pagination = {
  page: null,
  limit: null,
  totalPages: null,
  results: null
};

class PagedDataStore<T> {
  pagination: Pagination;
  data: T[];
  rootStore: RootStore;

  constructor(rootStore : RootStore) {
    makeObservable(this, {
      pagination: observable,
      data: observable,
      rootStore: false,
      setData: action,
      addData: action,
      removeDataItem: action,
      editDataItem: action,
      unsetData: action
    });

    this.pagination = emptyPagination;
    this.data = [];
    this.rootStore = rootStore;
  }

  setData(pagedData: PagedData<T>) {
    this.pagination = pagedData.pagination;
    this.data = pagedData.data;
  }

  addData(pagedData: PagedData<T>) {
    this.pagination = pagedData.pagination;
    this.data = [
      ... this.data,
      ... pagedData.data
    ];
  }

  addDataItem(item: T) {
    this.data.push(item);
  }

  removeDataItem(item: T, filter?: (t: T) => boolean) {
    const defaultFilter = (t: DatabaseItem) => {
      const basicItem = item as DatabaseItem;
      return t.id !== basicItem.id;
    };

    this.data = this.data.filter(filter ?? defaultFilter);
  }

  editDataItem(item: T, map?: (t: T) => T) {
    const defaultMap = (t: T) => {
      const basicItem = item as DatabaseItem;
      const basicT = t as DatabaseItem;
      return basicT.id === basicItem.id ? item : t;
    }

    this.data = this.data.map(map ?? defaultMap);
  }

  unsetData() {
    this.pagination = emptyPagination;
    this.data = [];
  }
}

export default PagedDataStore;
