import React, {ReactNode, useEffect} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import API, {catchHandler} from '@api';
import useStore from '@hook/useStore';
import useLazy from '@hook/useLazy';
import useKeyboardShortcut from '@hook/useKeyboardShortcut';

import LoadingCircle from './loading/LoadingCircle';
import TopBarFallBack from './top-bar/TopBarFallBack';

import '@styles/root.scss';
import '@styles/common-page.scss';
import '@styles/forms/forms.scss';
import '@styles/loading/loading.scss'
import {openNewCaseModal} from '../util';
import {TimedDisbursement} from '../store/LegalCaseStore';

export const Suspender = ({ children }: { children: ReactNode }) => (
  <React.Suspense fallback={ <LoadingCircle /> }>
    { children }
  </React.Suspense>
)

const Root = observer(() => {
  const { companyStore } = useStore();
  const location = useLocation();
  const navigate = useNavigate();

  useKeyboardShortcut('n', (e: KeyboardEvent) => {
    if (e.ctrlKey) openNewCaseModal();
  });

  useEffect(() => {
    API.getSelfCompany()
      .then(response => {
        companyStore.setData(response.data);
      })
      .catch(catchHandler);
  }, []);

  // Common redirects
  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/cases');
    }

    if (location.pathname === '/contacts') {
      navigate('/contacts/clients');
    }
  }, [location.pathname]);

  const TopBar = useLazy(import('./top-bar/TopBar'));

  const Case = useLazy(import('./cases/Case'));
  const CasesList = useLazy(import('./cases/cases-list/CasesList'));
  const CaseDocuments = useLazy(import('./cases/case-documents/CaseDocuments'));
  const DocumentEdit = useLazy(import('./cases/document-edit/DocumentEdit'));

  const CaseGlobalRates = useLazy(import('./cases/case-global/CaseGlobalRates'));
  const CaseGlobalNarrative = useLazy(import('./cases/case-global/CaseGlobalNarrative'));
  const CaseGlobalActivities = useLazy(import('./cases/case-global/CaseGlobalActivities'));
  const Costing = useLazy(import('./cases/costing/Costing'));

  const ContactList = useLazy(import('./contacts/contact-list/ContactList'));
  const ContactView = useLazy(import('./contacts/contact-view/ContactView'));

  const TopLevelSearch = useLazy(import('./top-level-search/TopLevelSearch'));

  return (
    <div className='root'>
      <React.Suspense fallback={ <TopBarFallBack /> }>
        <TopBar />
      </React.Suspense>
      <div className='routes'>
        <Routes location={ location }>
          <Route path='cases/*'>
            <Route index element={ <Suspender><CasesList /></Suspender>} />

            <Route path=':legalCaseId/*' element={ <Suspender><Case /></Suspender>}>
              <Route index element={ <Suspender><CaseDocuments /></Suspender> } />
              <Route path='documents/:route/:documentId' element={ <Suspender><DocumentEdit /></Suspender>} />
              <Route path='global/rates-units' element={ <Suspender><CaseGlobalRates /></Suspender>} />
              <Route path='global/narrative' element={ <Suspender><CaseGlobalNarrative /></Suspender>} />
              <Route path='global/timed-activities' element={ <Suspender><CaseGlobalActivities timedDisbursement={ TimedDisbursement.TIMED } /></Suspender>} />
              <Route path='global/disbursements' element={ <Suspender><CaseGlobalActivities timedDisbursement={ TimedDisbursement.DISBURSEMENT } /></Suspender>} />
              <Route path='price-calculations' element={ <Suspender><Costing /></Suspender> } />
            </Route>
          </Route>

          <Route path='contacts/*'>
            <Route path=':contactType' element={ <Suspender><ContactList /></Suspender>} />
            <Route path=':contactType/view/:id' element={ <Suspender><ContactView /></Suspender>} />
          </Route>

          <Route path='search' element={ <Suspender><TopLevelSearch /></Suspender> } />
        </Routes>
      </div>
    </div>
  )
});

export default Root;
