import UserStore, {User} from './UserStore';
import CompanyStore from './CompanyStore';
import {ActivityType, LegalCase} from './LegalCaseStore';
import {LocalKeys} from '@app';
import ApiStore from './ApiStore';
import ContextMenuStore from './ContextMenuStore';
import {Contact} from './ContactStore';
import DialogStore from './DialogStore';
import ModalStore from './ModalStore';
import {LegalDocument} from './DocumentStore';
import ObjectStore from './ObjectStore';
import PagedDataStore from './PagedDataStore';
import UiTopLayerStore from './UiTopLayerStore';
import {ArrayStore} from './ArrayStore';

export type DatabaseItem<T = unknown> = T & {
  id?: number;
  name?: string;
  archived?: boolean;
  preferred?: boolean;
  createdBy?: User;
  createdAt?: string;
  updatedBy?: User;
  updatedAt?: string;
  displayOrder?: number;
}

export class RootStore {
  apiStore: ApiStore;
  userStore : UserStore;
  legalCaseStore: PagedDataStore<LegalCase>;
  workingCaseStore: ObjectStore<LegalCase>;
  legalDocumentStore: PagedDataStore<LegalDocument>;
  workingDocumentStore: ObjectStore<LegalDocument>;
  companyStore: CompanyStore;
  contactStore: PagedDataStore<Contact>;
  activityTypeStore: ArrayStore<ActivityType>;
  modalStore: ModalStore;
  contextMenuStore: ContextMenuStore;
  dialogStore: DialogStore;
  uiTopLayerStore: UiTopLayerStore;

  constructor() {
    this.apiStore = new ApiStore(this);
    this.userStore = new UserStore(this);
    this.legalCaseStore = new PagedDataStore<LegalCase>(this);
    this.workingCaseStore = new ObjectStore<LegalCase>(this);
    this.legalDocumentStore = new PagedDataStore<LegalDocument>(this);
    this.workingDocumentStore = new ObjectStore<LegalDocument>(this);
    this.companyStore = new CompanyStore(this);
    this.contactStore = new PagedDataStore<Contact>(this);
    this.activityTypeStore = new ArrayStore<ActivityType>(this);
    this.modalStore = new ModalStore(this);
    this.contextMenuStore = new ContextMenuStore(this);
    this.dialogStore = new DialogStore(this);
    this.uiTopLayerStore = new UiTopLayerStore(this);
  }

  unsetAll() {
    localStorage.removeItem(LocalKeys.CD_LOCAL_USER);
    this.userStore.unsetData();
    this.legalCaseStore.unsetData();
    this.legalDocumentStore.unsetData();
    this.companyStore.unsetData();
    this.contactStore.unsetData();
    this.contextMenuStore.unsetData();
    this.dialogStore.unsetData();
  }
}

const store = new RootStore()
export default store;
