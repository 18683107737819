import React from 'react';
import "@styles/loading/loading.scss";

const LoadingCircle = () => {
  return (
    <div className='loading-circle'>
      <svg xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="40" />
      </svg>
    </div>
  )
}

export default LoadingCircle;
