import React from 'react';
import { createRoot } from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import StoreProvider from './context/StoreContext';
import App from './App';

import '@styles/global.scss';

const container = document.getElementById('react-root');
const root = createRoot(container);

root.render(
  <StoreProvider>
    <BrowserRouter basename={`/`}>
      <App />
    </BrowserRouter>
  </StoreProvider>
);
