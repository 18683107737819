import {observable, action, makeObservable} from 'mobx';
import { RootStore } from './RootStore';

export interface Company {
  id?: number;
  name?: string;
}

class CompanyStore {
  data: Company;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      data: observable,
      setData: action,
      unsetData: action,
      rootStore: false
    })

    this.data = {};
    this.rootStore = rootStore;
  }

  setData(data: Company) {
    this.data = data;
  }

  unsetData() {
    this.data = {};
  }
}

export default CompanyStore;
