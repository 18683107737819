import React, {useEffect, useState} from 'react';

export type BaseInputProps = {
  id?: string;
  type?: string;
  name: string;
  label?: string;
  value: string;
  onChange?: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyUp?: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  autoFocus?: boolean;
  autoComplete?: string;
  forceCheckValid?: boolean;
  minLength?: number;
  maxLength?: number;
  required?: boolean;
  errorMessage?: string;
}

type InputState = {
  props: BaseInputProps;
  inputId: string;
  error?: boolean;
}

export default ({ props, inputId } : InputState) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (props.forceCheckValid && props.required) checkValid();
  }, [props.forceCheckValid]);

  useEffect(() => {
    if (props.required && error) checkValid();
  }, [props.value]);

  const focusHandler = () => {
    setFocus(true);
  }

  const blurHandler = () => {
    if (props.required) checkValid();
    setFocus(false);
  }

  const checkValid = () => {
    const input = document.getElementById(inputId) as HTMLInputElement | HTMLTextAreaElement;
    input.required = true;
    setError(!input.checkValidity());
    input.required = false;
  }

  return {
    focus,
    error,
    focusHandler,
    blurHandler,
    checkValid
  }
}
