import React, {useEffect, useRef} from 'react';
import Modal from './Modal';
import {observer} from 'mobx-react-lite';
import useStore from '../hook/useStore';
import {DialogAction} from '../store/DialogStore';

const Dialog = observer(() => {
  const { dialogStore } = useStore();
  const { size, title, description, actions, returnFocus, onClose } = dialogStore;
  const autoFocusRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setTimeout(() => {
      autoFocusRef.current?.focus();
    }, 50);
  }, [autoFocusRef]);

  const closeHandler = () => {
    dialogStore.unsetData()
    onClose && onClose();
  }

  const clickHandler = (dialogAction: DialogAction) => {
    dialogAction.action && dialogAction.action();
    closeHandler();
  }

  return (
    <Modal onClose={ closeHandler }
           size={ size }
           title={ title }
           returnFocus={ returnFocus }
           className='modal--dialog'>
      {
        description &&
        <div className='dialog-description'>
          { description }
        </div>
      }
      <div className='dialog-actions flex flex-row flex-center'>
        {
          actions.map((action, i) => (
            <button type='button'
                    className='button'
                    ref={ action.autoFocus && autoFocusRef }
                    key={ `dl_${action.label}` }
                    title={ action.label }
                    data-intent={ action.intent ?? 'none' }
                    onClick={ () => clickHandler(action) }>
              { action.label }
            </button>
          ))
        }
      </div>
    </Modal>
  )
});

export default Dialog;
