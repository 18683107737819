import React, { createContext } from 'react';

type ModalContextTypes = {
  data: ModalContextData;
  setData: (data: ModalContextData) => void;
}

export interface ModalContextData {
  overlay?: HTMLDivElement;
  window?: HTMLDivElement;
  uuid?: string;
}

export const ModalContext = createContext<ModalContextTypes | undefined>(undefined);
ModalContext.displayName = 'ModalContext';
