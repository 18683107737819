import React, {ForwardedRef, forwardRef, useEffect, useRef, useState} from 'react';

import LoadingCircle from '@component/loading/LoadingCircle';
import WithTooltip from '@component/WithTooltip';
import {clearFromTopLayer, openOnTopLayer} from '@util';
import '@styles/drop-down/drop-down.scss';
import DropDownContent from './DropDownContent';
import {UiTopLayerAlignment} from '../../store/UiTopLayerStore';

type DropDownProps = {
  id?: string;
  busy?: boolean;
  loadingTip?: string;
  tooltip?: string;
  contentClassName?: string;
  getValue: () => (string | number);
  children: React.ReactNode;
  disabled?: boolean;
  disabledTip?: string;
  valid?: boolean;
  required?: boolean;
  alignment?: UiTopLayerAlignment;
  width?: number;
};

const DropDown = forwardRef(({
                               id,
                               busy,
                               loadingTip,
                               tooltip,
                               contentClassName,
                               getValue,
                               children,
                               disabled,
                               disabledTip,
                               valid,
                               required,
                               width,
                               alignment = UiTopLayerAlignment.FIT
                             }: DropDownProps,
                             ref : ForwardedRef<HTMLDivElement>) => {
  const [open, setOpen] = useState<boolean>(false);
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const [dropDownUuid, setDropDownUuid] = useState<string>(null);
  const valueRef = useRef<HTMLButtonElement>(null);
  const exitTimeout = useRef<NodeJS.Timeout>(null);
  const value = getValue();

  useEffect(() => {
    clearTimeout(exitTimeout.current);
    return () => clearFromTopLayer(dropDownUuid);
  }, []);

  useEffect(() => {
    if (open) {
      openDropDown();
    } else {
      closeDropDown();
    }
  }, [open]);

  const openDropDown = () => {
    if (busy) return;

    if (dropDownUuid) clearFromTopLayer(dropDownUuid);

    const uuid = openOnTopLayer({
      parentElement: valueRef.current,
      children: <DropDownContent
        className={ contentClassName }
        onClose={ () => setOpen(false) }
        openingPosition={ getOpeningPosition() }
        valueRef={ valueRef.current }>
        { children }
      </DropDownContent>,
      alignment,
      width
    });

    setDropDownUuid(uuid);
  }

  const closeDropDown = () => {
    clearFromTopLayer(dropDownUuid);
    setDropDownUuid(null);
  }

  const getOpeningPosition = () => {
    const bounds = valueRef.current.getBoundingClientRect();

    if (bounds.top <= window.innerHeight * 0.66) {
      return 'bottom';
    }

    return 'top';
  }

  const toggleOpen = () => {
    if (disabled) return;
    setOpen(!open);
  }

  const getToolTip = () => {
    if (busy && loadingTip) return loadingTip;
    if (busy) return "Fetching information...";
    if (disabled && disabledTip) return disabledTip;
    return tooltip;
  }

  const keyDownHandler = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Enter') toggleOpen();
  }

  const notValid =  (typeof valid !== 'undefined' && valid !== null && !valid);

  return (
    <div className='drop-down'
         ref={ ref }
         onFocus={ () => setHasFocus(true) }
         onBlur={ () => setHasFocus(false) }
         data-has-focus={ hasFocus }
         data-open={ open }
         aria-disabled={ disabled }
         data-error={ notValid }
         data-opening-position={ open && getOpeningPosition() || 'closed' }
         data-busy={ busy }>
      <WithTooltip
        disabled={ open || !tooltip && !busy }
        tooltip={ getToolTip() }>
        <button className='drop-down__value'
                type='button'
                id={ id }
                ref={ ref => valueRef.current = ref }
                onKeyDown={ keyDownHandler }
                onMouseDown={ toggleOpen }>
          <span>{ value }</span>
        </button>
      </WithTooltip>
      {
        busy &&
        <LoadingCircle />
      }
      {
        notValid &&
        <img src='/img/svg/error-red.svg' alt='Error' />
      }
      {
        notValid &&
        <div className='error'>This field is required</div>
      }
    </div>
  )
})

export default DropDown;
