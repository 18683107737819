import {action, makeObservable, observable} from 'mobx';

import {Client} from './ClientStore';
import {FeeEarner} from './FeeEarnerStore';
import {Court} from './CourtStore';
import {DatabaseItem, RootStore} from './RootStore';

export type Contact = Client & FeeEarner & Court;

export enum ContactTypes {
  CLIENT= 'clients',
  FEE_EARNER = 'fee-earners',
  COURT = 'courts'
}

class ContactStore {
  data: Contact[];
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      data: observable,
      setData: action,
      addData: action,
      rootStore: false
    });

    this.data = [];

    this.rootStore = rootStore;
  }

  setData(data: Contact[]) {
    this.data = data;
  }

  addData(data: Contact[]) {
    this.data = [
      ... this.data,
      ... data
    ];
  }

  unsetData() {
    this.data = null;
  }
}

export default ContactStore;
