import {action, makeObservable, observable, computed} from 'mobx';
import { RootStore } from "./RootStore";
import {LocalKeys} from '../App';

export enum UserRoles {
  ROLE_USER = 'ROLE_USER',
  ROLE_ADMIN = 'ROLE_ADMIN'
}

export interface User {
  id?: number;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  roles?: UserRoles[];
}

class UserStore {
  data: User;
  rootStore: RootStore;

  constructor(rootStore : RootStore) {
    makeObservable(this, {
      data: observable,
      setData: action,
      unsetData: action,
      name: computed,
      rootStore: false
    });

    this.data = {};
    this.rootStore = rootStore;
  }

  setData(user: User ) {
    this.data = user;
  }

  unsetData() {
    this.data = {}
    localStorage.removeItem(LocalKeys.CD_LOCAL_USER);
  }

  get name() : string {
    return `${this.data.firstName} ${this.data.lastName}`;
  }

  hasRole(role: UserRoles) : boolean {
    return this.data.hasOwnProperty('roles') &&
      this.data.roles.indexOf(role) >= 0;
  }
}

export default UserStore;
