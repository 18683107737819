import React, {createContext, ReactNode} from 'react';

import Store, { RootStore } from '@store/RootStore';

const store : RootStore = Store;

export interface BasicContextType<T> {
  data: T;
  setData: (t: T) => void;
}

export const StoreContext = createContext<RootStore | undefined>(undefined);
StoreContext.displayName = "StoreContext";

const StoreProvider = ({ children } : { children: ReactNode }) => {
  const root = store ?? new RootStore();
  return <StoreContext.Provider value={ root }>{ children }</StoreContext.Provider>
}

export default StoreProvider;
