import React, {useRef} from 'react';
import useScrollEvent from '../hook/useScrollEvent';

type ScrollEndTriggerProps<T> = {
  className?: string;
  onEndTrigger: () => void;
  children?: React.ReactNode;
  deps?: React.DependencyList;
  busy?: boolean;
}

const ScrollEndTrigger = <T, >({
                                 className,
                                 onEndTrigger,
                                 children,
                                 deps,
                                 busy
                               }: ScrollEndTriggerProps<T>) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useScrollEvent(containerRef.current, (stop) => {
    const { clientHeight, scrollHeight, scrollTop } = containerRef.current;
    const maxScroll = scrollHeight - clientHeight;

    if (scrollTop >= maxScroll) {
      if (!busy) onEndTrigger();
      stop();
      return;
    }
  }, !busy, [deps]);

  return (
    <div className={`scroll-end-trigger scrollable ${className}`}
         ref={ containerRef }>
      { children }
    </div>
  )
}

export default ScrollEndTrigger;
