import React, {useEffect, useState} from 'react';
import dayjs, {Dayjs} from 'dayjs';
import FocusTrap from 'focus-trap-react';

import DatePickerCalendarHead from './DatePickerCalendarHead';
import DatePickerCalendarMonth from './DatePickerCalendarMonth';
import DatepickerCalendarYear from './DatePickerCalendarYear';

import Events from '@events';

export enum CalendarViews {
  MONTH,
  YEAR
}

type DatePickerCalendarProps = {
  value: Dayjs;
  min?: Dayjs;
  max?: Dayjs;
  onChange: (date: Dayjs) => void;
  blockedDates?: (date: Dayjs) => boolean;
  onClose?: () => void;
}

const DatePickerCalendar = ({
                              value,
                              min,
                              max,
                              onChange,
                              blockedDates,
                              onClose
                            } : DatePickerCalendarProps) => {
  const [currentDate, setCurrentDate] = useState<Dayjs>(value ?? dayjs());
  const [focusId, setFocusId] = useState<string>(null);
  const [view, setView] = useState<CalendarViews>(CalendarViews.MONTH);

  useEffect(() => {
    addEventListener(Events.KEY_DOWN, keyHandler);
    if (focusId) regainFocus();
    return () => removeEventListener(Events.KEY_DOWN, keyHandler);
  }, [currentDate]);

  const keyHandler = (e: KeyboardEvent) => {
    if (e.key === 'ArrowLeft') previousHandler();
    if (e.key === 'ArrowRight') nextHandler();
  }

  const previousHandler = () => {
    const newDate = view === CalendarViews.MONTH ?
      currentDate.subtract(1, 'month') :
      currentDate.subtract(1, 'year');

    setCurrentDate(newDate);
  }

  const nextHandler = () => {
    const newDate = view === CalendarViews.MONTH ?
      currentDate.add(1, 'month') :
      currentDate.add(1, 'year');

    setCurrentDate(newDate);
  }

  const regainFocus = () => {
    document.getElementById(focusId)?.focus();
  }

  const changeViewHandler = (newView: CalendarViews) => {
    setView(newView);
  }

  const changeValueHandler = (date: Dayjs) => {
    onChange(date);
    onClose();
  }

  const changeViewDateHandler = (date: Dayjs) => {
    setCurrentDate(date);
    setView(CalendarViews.MONTH);
  }

  const focusHandler = (e: React.FocusEvent<HTMLButtonElement>) => {
    setFocusId(e.currentTarget.id);
  }

  return (
    <FocusTrap focusTrapOptions={{
      initialFocus: false,
      clickOutsideDeactivates: true,
      onDeactivate: onClose
    }}>
      <div className='date-picker-calendar'>
        <DatePickerCalendarHead
          view={ view }
          currentDate={ currentDate }
          onChangeView={ changeViewHandler }
          onPrevious={ previousHandler }
          onNext={ nextHandler }
        />
        {
          view === CalendarViews.MONTH ?
            <DatePickerCalendarMonth
              value={ value }
              currentDate={ currentDate }
              onChange={ changeValueHandler }
              onFocus={ focusHandler }
              min={ min }
              max={ max }
              blockedDates={ blockedDates }
            />
            :
            <DatepickerCalendarYear
              currentDate={ currentDate }
              onChange={ changeViewDateHandler }
              onFocus={ focusHandler }
            />
        }
      </div>
    </FocusTrap>
  )
}

export default DatePickerCalendar;
