import React from 'react';
import {RootStore} from './RootStore';
import {action, makeObservable, observable} from 'mobx';
import {ModalSizes} from '../component/Modal';

/** Rationale: Easy way of rendering and tracking the global component
 *  independent of the component that opened the dialog.
 */

export enum DialogActionIntent {
  POSITIVE = 'positive',
  NEGATIVE = 'negative'
}

export interface DialogAction {
  label: string;
  action?: () => void;
  autoFocus?: boolean;
  intent?: DialogActionIntent;
}

export interface DialogProps {
  size?: ModalSizes;
  title?: string;
  description?: React.ReactNode;
  onClose?: () => void;
  actions: DialogAction[];
  returnFocus?: boolean;
}

class DialogStore {
  rootStore: RootStore;
  size: ModalSizes;
  open: boolean;
  title: string;
  description: React.ReactNode;
  onClose: () => void;
  actions: DialogAction[];
  returnFocus?: boolean;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      size: observable,
      open: observable,
      title: observable,
      description: observable,
      actions: observable,
      returnFocus: observable,
      setSize: action,
      setOpen: action,
      setTitle: action,
      setDescription: action,
      setOnClose: action,
      setActions: action,
      setReturnFocus: action,
      unsetData: action,
      rootStore: false
    });

    this.rootStore = rootStore;
  }

  setSize(size: ModalSizes) {
    this.size = size;
  }

  setOpen(open: boolean) {
    this.open = open;
  }

  setTitle(title: string) {
    this.title = title;
  }

  setDescription(description: React.ReactNode) {
    this.description = description;
  }

  setOnClose(onClose: () => void) {
    this.onClose = onClose;
  }

  setActions(actions: DialogAction[]) {
    this.actions = actions;
  }

  setReturnFocus(returnFocus: boolean) {
    this.returnFocus = returnFocus;
  }

  unsetData() {
    this.open = false;
    this.title = null;
    this.description = null;
    this.onClose = null;
    this.actions = null;
  }
}

export default DialogStore;
