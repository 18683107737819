import {action, makeObservable, observable} from 'mobx';
import {DatabaseItem, RootStore} from './RootStore';

export class ArrayStore<T> {
  data: T[];
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      data: observable,
      setData: action,
      addData: action,
      unsetData: action,
      rootStore: false
    });

    this.data = [];
    this.rootStore = rootStore;
  }

  setData(data: T[]) {
    this.data = data;
  }

  addData(data: T[]) {
    this.data = [
      ... this.data,
      ...data
    ];
  }

  addDataItem(item: T) {
    this.data.push(item);
  }

  removeDataItem(item: T, filter?: (t: T) => boolean) {
    const defaultFilter = (t: DatabaseItem) => {
      const basicItem = item as DatabaseItem;
      return t.id !== basicItem.id;
    };

    this.data = this.data.filter(filter ?? defaultFilter);
  }

  editDataItem(item: T, map?: (t: T) => T) {
    const defaultMap = (t: T) => {
      const basicItem = item as DatabaseItem;
      const basicT = t as DatabaseItem;
      return basicT.id === basicItem.id ? item : t;
    }

    this.data = this.data.map(map ?? defaultMap);
  }

  unsetData() {
    this.data = [];
  }
}
