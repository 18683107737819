import React, {useContext} from 'react';
import {StoreContext} from '@context/StoreContext';
import {RootStore} from '@store/RootStore';

export default () : RootStore => {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useStore must be used within StoreProvider');
  }

  return context;
}
