import * as _ from 'lodash';
import React from 'react';
import {DialogActionIntent, DialogProps} from './store/DialogStore';
import store, {DatabaseItem} from './store/RootStore';
import {ModalProps, ModalSizes} from './component/Modal';
import {v4 as uuidv4} from 'uuid';
import {ContextMenuProps} from './store/ContextMenuStore';
import {Contact, ContactTypes} from './store/ContactStore';
import API from './api/API';
import {ModalId} from './store/ModalStore';
import NewCaseForm from './component/forms/NewCaseForm';
import CourtForm from './component/forms/CourtForm';
import FeeEarnerForm from './component/forms/FeeEarnerForm';
import ClientForm from './component/forms/ClientForm';
import SortDefaults from './SortDefaults';
import SearchFieldDefaults from './SearchFieldDefaults';
import {
  UiTopLayerAlignment,
  UiTopLayerElement,
  UiTopLayerId
} from './store/UiTopLayerStore';
import {DocumentEditContextData} from './context/DocumentEditContext';
import {FeeEarner} from './store/FeeEarnerStore';
import {toJS} from 'mobx';

export type V2 = {
  x?: number;
  y?: number;
};

export const strToBool = (string: string) : boolean|string => {
  if (string === "true") return true;
  if (string === "false") return false;
  return string;
}

/** Avoids having to differentiate in query selectors **/
export type ElementHTML = Element | HTMLElement;

/** Clamp a number between a minimum and maximum value **/
export const clamp = (min: number, value: number, max: number): number =>
  value < min ? min : value > max ? max : value;

/** Check if an HTML Element has a specific HTML Element ancestor **/
export const hasAncestor = (child: ElementHTML, parent: ElementHTML): boolean => {
  let target = child;

  if (!target) return false;

  while(target.parentElement !== document.body) {
    if (!target || target === document.body) return false;
    if (target.parentElement === parent || target === parent) {
      return true;
    }

    if (target.parentElement) {
      target = target.parentElement;
    } else {
      return false;
    }
  }

  return false;
};

/** Check if an element can be focused **/
export const isArrowFocusable = (el: ElementHTML): boolean => {
  return el.getAttribute('data-arrow-focusable') === 'true';
}

/** Check if an element is inside a drop down **/
export const isInDropDown = (el: ElementHTML): boolean => {
  if (el.className === 'drop-down__value') return false;

  const dropDowns = document.querySelectorAll('.drop-down');
  for (let i = 0; i < dropDowns.length; i++) {
    if (hasAncestor(el, dropDowns[i])) return true;
  }
}

/** Title case from any case input **/
export const titleCase = (string: string): string => {
  return _.startCase(_.toLower(string));
}

/** Turn mouse co-ordinates into a V2 object **/
export const mouseToV2 = (e: React.MouseEvent | MouseEvent) => {
  return { x: e.clientX, y: e.clientY };
}


/** Check a value for numeric values and convert as appropriate **/
export const checkAndConvertNumber = (value: string) => {
  const parsed = parseFloat(value);
  return !isNaN(parsed) ? parsed : value;
}

/** Create a context menu **/
export const createContextMenu = ({
                                    position,
                                    backupParent,
                                    children
                                  } : ContextMenuProps) => {
  const { contextMenuStore } = store;
  contextMenuStore.setOpen(false);
  contextMenuStore.setPosition(position);
  backupParent && contextMenuStore.setBackupParent(backupParent);
  contextMenuStore.setChildren(children);

  setTimeout(() => {
    contextMenuStore.setOpen(true);
  }, 20);
}

/** Create a Dialog **/
export const createDialog = ({
                               size,
                               title,
                               description,
                               returnFocus,
                               onClose,
                               actions
                             } : DialogProps) => {
  const { dialogStore } = store;
  size && dialogStore.setSize(size);
  title && dialogStore.setTitle(title);
  description && dialogStore.setDescription(description);
  returnFocus && dialogStore.setReturnFocus(returnFocus);
  onClose && dialogStore.setOnClose(onClose);
  dialogStore.setActions(actions);
  dialogStore.setOpen(true);
};

/** Create a Modal **/
export const createModal = (modal: ModalProps) => {
  const { modalStore } = store;

  if (modalStore.checkId(modal.id)) return;

  const uuid = uuidv4();
  modalStore.addModal({
    ... modal,
    uuid
  });

  return uuid;
}

/** Specifically a new case modal as used in a few places **/
export const openNewCaseModal = () => {
  createModal({
    title: 'Create a new case',
    id: ModalId.NEW_CASE,
    children: <NewCaseForm />,
    beforeClose: (uuid: string) => {
      createDiscardWarning({
        title: "Discard new case?",
        description: "Are you sure you want to discard this case?",
        modalUuid: uuid
      })

      return false;
    }
  });
}

/** Why didn't I make this a year ago **/
export const getFeeEarnerName = (feeEarner: FeeEarner) => `${feeEarner?.firstName ?? ''} ${feeEarner?.lastName ?? '' }`;

/** Title case name of contact type **/
export const getContactTypeTitle = (contactType: ContactTypes, singular?: boolean) => {
  const displayName = singular ? contactType.replace(/s$/, '') : contactType;
  return _.startCase(displayName.replace(/-/g, ' '));
}

/** Differentiate to get the name of a contact **/
export const getContactName = (contact: Contact) => {
  let name = '';

  if ("firstName" in contact) {
    name = `${contact.firstName} ${contact.lastName}`;
  } else if ("name" in contact) {
    name = contact.name;
  }

  return name;
}

/** Get top level API route based on contact type **/
export const getContactApiMethod = (contactType: ContactTypes, singular?: boolean) => {
  return ((contactType : ContactTypes) => {
    switch (contactType) {
      case ContactTypes.CLIENT:
        return singular ? API.getClient : API.getClients;

      case ContactTypes.FEE_EARNER:
        return singular ? API.getFeeEarner : API.getFeeEarners;

      case ContactTypes.COURT:
        return singular ? API.getCourt : API.getCourts;
    }
  })(contactType);
}

/** Get the correct type of form modal for a contact **/
export const getContactModal = ({
                                  contact,
                                  contactType,
                                  onSuccess,
                                  modalProps
                                } : {
  contact?: Contact,
  contactType: ContactTypes,
  onSuccess: (contact: Contact) => void,
  modalProps: ModalProps
}) : ModalProps => {
  switch(contactType) {
    case ContactTypes.CLIENT:
      return {
        ... modalProps,
        size: ModalSizes.LARGE,
        id: ModalId.CLIENT_FORM,
        title: contact ? 'Edit client information' : 'Add a new client',
        children: <ClientForm onSuccess={ onSuccess } client={ contact } />
      };

    case ContactTypes.FEE_EARNER:
      return {
        ... modalProps,
        size: ModalSizes.LARGE,
        id: ModalId.FEE_EARNER_FORM,
        title: contact ? 'Edit fee earner information' : 'Add a new fee earner',
        children: <FeeEarnerForm onSuccess={ onSuccess } feeEarner={ contact } />
      };

    case ContactTypes.COURT:
      return {
        ... modalProps,
        size: ModalSizes.LARGE,
        id: ModalId.COURT_FORM,
        title: contact ? 'Edit court information' : 'Add a new court',
        children: <CourtForm onSuccess={ onSuccess } court={ contact } />
      }
  }
}

export const createDiscardWarning = ({ title, description, modalUuid } : {
  title: string,
  description: string,
  modalUuid: string
}) => {
  const { modalStore } = store;

  createDialog({
    title,
    description: <p>{ description }</p>,
    size: ModalSizes.MEDIUM,
    actions: [
      {
        label: "Yes",
        action: () => {
          modalStore.removeModal(modalUuid);
        },
        intent: DialogActionIntent.POSITIVE
      },
      {
        label: "No",
        intent: DialogActionIntent.NEGATIVE
      }
    ]
  })
}

export const createGenericEditDiscardWarning = (uuid: string) => {
  createDiscardWarning({
    title: 'Discard changes?',
    description: 'Your changes will not be saved',
    modalUuid: uuid
  });

  return false;
}

export const getContactSearchFields = (contactType: ContactTypes) : string => {
  switch(contactType) {
    case ContactTypes.CLIENT:
      return SearchFieldDefaults.CLIENTS;

    case ContactTypes.FEE_EARNER:
      return SearchFieldDefaults.FEE_EARNERS;

    case ContactTypes.COURT:
      return SearchFieldDefaults.COURTS;
  }
}

export const getContactSort = (contactType: ContactTypes) : string => {
  switch(contactType) {
    case ContactTypes.CLIENT:
      return SortDefaults.CLIENTS;

    case ContactTypes.FEE_EARNER:
      return SortDefaults.FEE_EARNERS;

    case ContactTypes.COURT:
      return SortDefaults.COURTS;
  }
}

export const openOnTopLayer = (element : UiTopLayerElement) : string => {
  return store.uiTopLayerStore.render(element);
};

export const clearFromTopLayer = (uuid: string | UiTopLayerId) => {
  console.log(toJS(store.uiTopLayerStore.elements));
  store.uiTopLayerStore.clear(uuid);
}

export const clearTopLayer = () => {
  store.uiTopLayerStore.clearAll();
}

export const checkRowEditing = (contextData: DocumentEditContextData) : Promise<void> => {
  return new Promise((resolve, reject) => {
    if (contextData === null) resolve();
    if (contextData?.editObject !== null) {
      const patchObject = getPatchObject(contextData.editObject, contextData.editObjectChanges);

      if (Object.keys(patchObject).filter(key => key !== 'id').length <= 0) {
        resolve();
      } else {
        createDialog({
          size: ModalSizes.SMALL,
          title: 'Discard current edits?',
          description: <p>Do you want to discard the edits you have made to this item?</p>,
          actions: [
            {
              intent: DialogActionIntent.POSITIVE,
              label: 'Yes',
              action: () => resolve()
            },
            {
              intent: DialogActionIntent.NEGATIVE,
              label: 'No',
              action: () => reject()
            }
          ]
        })
      }
    } else {
      resolve();
    }
  });
}

export const getPatchObject = <T, >(originalObject: T, newObject: T) => {
  let patchObject : T = {} as T;

  for (const k in newObject) {
    const key = k as keyof T;

    if (newObject[key] !== originalObject[key]) {
      patchObject = {
        ... patchObject,
        [key]: newObject[key]
      }
    }

    if (key === "id") {
      patchObject[key] = newObject[key];
    }
  }

  return {
    ... patchObject
  };
}

export const findWithId = <T, >(id: number, array: DatabaseItem<T>[]) => {
  for (const item of array) {
    if (item.id === id) return item;
  }
}