import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {LocalKeys} from '@app';
import API, {catchHandler} from '@api';
import useStore from '@hook/useStore';

import LoginForm from './LoginForm';
import LoadingCircle from '@component/loading/LoadingCircle';

import '@styles/login.scss';

export interface LoginFormData {
  emailAddress?: string;
  password?: string;
  rememberMe?: boolean;
}

const Login = () => {
  const [busy, setBusy] = useState<boolean>(false);
  const { userStore } = useStore();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<LoginFormData>({
    emailAddress: '',
    password: '',
    rememberMe: false
  });

  useEffect(() => {
    if (localStorage.getItem(LocalKeys.CD_LOCAL_USER)) {
      navigate('/');
    }
  })

  const changeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setFormData({
      ... formData,
      [e.currentTarget.name]: e.currentTarget.value
    });
  }

  const toggleRememberHandler = (value: boolean) => {
    setFormData({
      ... formData,
      rememberMe: value
    });
  }

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setBusy(true);
    API.login(formData)
      .then(response => {
        setBusy(false);
        userStore.setData(response.data);
        localStorage.setItem(LocalKeys.CD_LOCAL_USER, JSON.stringify(response.data));

        // TODO navigate to user last page from data
        navigate('/', { replace: true });
      })
      .catch(error => {
        setBusy(false);
        catchHandler(error);
      })
  }

  return (
    <div className='login flex flex-column'
         data-busy={ busy }>
      <LoginForm
        formData={ formData }
        onChange={ changeHandler }
        onSubmit={ submitHandler }
        onToggleRemember={ toggleRememberHandler }
      />
      {
        busy &&
        <LoadingCircle />
      }
    </div>
  )
};

export default Login;
