import React, {ForwardedRef, forwardRef, useState} from 'react';
import {v4 as uuid4} from 'uuid';
import * as _ from 'lodash';

import useInputState, {BaseInputProps} from '@hook/useInputState';
import '@styles/input/text-input.scss';
import WithTooltip from '../WithTooltip';

type TextInputProps =  BaseInputProps & {
  onClear?: () => void;
}

const TextInput = forwardRef((props : TextInputProps,
                              ref: ForwardedRef<HTMLInputElement>) => {
  const [inputId] = useState<string>(props.id || uuid4());
  const inputState = useInputState({
    props,
    inputId
  })

  return (
    <div className='input input--text'
         data-error={ inputState.error }
         data-filled={ props.value.length > 0 }
         data-focus={ inputState.focus }>
      <label htmlFor={ inputId }>{ props.label || _.startCase(props.name) }</label>
      <input
        type={ props.type || 'text' }
        id={ inputId }
        name={ props.name }
        ref={ ref }
        value={ props.value }
        onChange={ props.onChange }
        minLength={ props.minLength }
        maxLength={ props.maxLength }
        onKeyUp={ props.onKeyUp }
        onFocus={ inputState.focusHandler }
        onBlur={ inputState.blurHandler }
        autoFocus={ props.autoFocus }
        autoComplete={ props.autoComplete }
      />
      {
        inputState.error &&
        <div className='error'>{ props.errorMessage ?? 'Please fill in this field'}</div>
      }
      {
        inputState.error &&
        <img src='/img/svg/error-red.svg' alt='Error' />
      }
      {
        (props.onClear && props.value?.length > 0) &&
        <WithTooltip tooltip="Clear input" className='clear'>
          <button type='button'
                  className='clear-button'
                  onClick={ props.onClear }
                  aria-label='Clear'>
            <img src='/img/svg/clear.svg' alt='Clear' />
          </button>
        </WithTooltip>
      }
    </div>
  )
});

export default TextInput;
