import React, {useContext} from 'react';

import {DropDownContext} from '@context/DropDownContext';

import {Client} from '@store/ClientStore';
import {FeeEarner} from '@store/FeeEarnerStore';
import {Court} from '@store/CourtStore';

export const CommonItem = ({ label, onClick } : {
  label: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}) => {
  const dropDownContext = useContext(DropDownContext);

  const clickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClick(e);
    dropDownContext.onClose();
  }

  return (
    <button onClick={ clickHandler } className='drop-down-common-item'>
      { label }
    </button>
  )
}

export const ClientItem = ({ client } : { client: Client }) => (
  <>
    <span>{ client.name }</span>
    <small>{ client.town }</small>
  </>
);

export const CourtItem = ({ court } : { court: Court }) => (
  <>
    <span>{ court.name }</span>
  </>
);

export const FeeEarnerItem = ({ feeEarner } : { feeEarner: FeeEarner }) => (
  <>
    <span>{ feeEarner.firstName } { feeEarner.lastName }</span>
    <small>{ feeEarner.client.name }</small>
  </>
);
