import {Params} from '@api';
import {Client, LegalCaseClient} from './ClientStore';
import {FeeEarner, LegalCaseFeeEarner} from './FeeEarnerStore';
import {Court, CourtType} from './CourtStore';
import {DatabaseItem} from './RootStore';

export interface LegalCaseParams extends Params {
  clientId?: number;
  feeEarnerId?: number;
  courtId?: number;
}

export interface LegalCase {
  id?: number;
  caseTitle?: string;
  clientReference?: string;
  cdReference?: string;
  client?: Client;
  legalCaseClient?: LegalCaseClient;
  clients?: Client[];
  clientId?: number;
  feeEarners?: FeeEarner[];
  feeEarnerIds?: number[];
  court?: Court;
  legalCaseCourts?: Court[];
  courts?: Court[];
  courtId?: number;
  narrative?: string;
  narrativeEnhancement?: string;
  narrativeFPC?: string;
  preferred?: boolean;
  archived?: boolean;
}

export enum CaseFunding {
  LEGAL_AID = "Legal Aid",
  PRIVATE_CLIENT = "Private Client",
  CONDITIONAL_FEE_AGREEMENT = "Conditional Fee Agreement",
  BEFORE_EVENT_INSURANCE = "Before the Event Insurance"
}

export interface LegalCaseQuestionnaire {
  id?: number;
  caseTypeIds?: number[];
  caseTypes?: DatabaseItem[];
  legalCase?: LegalCase;
  natureOfFunding?: CaseFunding;
  court?: Court;
  courtId?: number;
  courtType?: CourtType;
  courtTypeId?: number;
  courtTypeChangeId?: number;
  wasCourtTypeChange?: boolean;
  courtTypeChange?: CourtType;
  mainFeeEarner?: FeeEarner;
  mainFeeEarnerId?: number;
  wasInstructedByDifferentClient?: boolean;
  instructedClient?: Client;
  clientId?: number;
  legalAidCertificate?: string;
  legalAidCertificateDate?: string;
  legalAidCertificateArea?: string;
  legalAidSchemeApplies?: boolean;
  legalAidSchemeType?: string;
  legalAidAdvocacyCosts?: boolean;
  legalAidDischargeRevoke?: boolean;
  legalAidDischargeRevokeDate?: string;
  legalAidMultipleClients?: boolean;
  privateClientCareLetterDate?: string;
  privateClientChargingRate?: number;
  privateClientLetterSufficient?: boolean;
  privateClientLetterStatus?: string;
  conditionalFeeDate?: string;
  conditionalFeeChargingRate?: number;
  conditionalFeeSuccessProvided?: boolean;
  conditionalFeeSuccessChargeable?: boolean;
  conditionalFeeSuccessIncluded?: boolean;
  finished?: boolean;
}

export enum QuestionnaireQuestionAnswerType {
  DROPDOWN = "dropdown",
  DATE = "date",
  NUMBER = "number"
}

export interface QuestionnaireQuestion {
  id?: number;
  sectionNumber?: number;
  previousAnswer?: string;
  question?: string;
  answerType?: QuestionnaireQuestionAnswerType;
  answers?: QuestionnaireAnswer[];
  dependentQuestionId?: number;
  dependentAnswer?: string;
  databaseField?: string;
}

export interface QuestionnaireAnswer {
  id?: number;
  answerText?: string;
  answerValue?: string;
  answerType?: string;
}

export interface ActivityCode extends DatabaseItem {
  code?: string;
  codeType?: string;
}

export interface ActivityType extends DatabaseItem {
  timedDisbursement?: TimedDisbursement;
  userEntered?: boolean;
}

export enum TimedDisbursement {
  TIMED = "timed",
  DISBURSEMENT = "disbursement"
}

export interface Counsel extends DatabaseItem {
  accountNumber?: string;
  legalCase?: LegalCase;
}

export interface ExpertGroup extends DatabaseItem {
  groupCode?: string;
}

export interface ExpertCode extends DatabaseItem {
  code?: string;
  expertGroup?: ExpertGroup;
}

export interface LegalCaseActivity {
  id?: number;
  legalCase?: LegalCase;
  legalCaseId?: number;
  activityDate?: string;
  description?: string;
  feeEarner?: FeeEarner;
  feeEarnerId?: number;
  activityType?: ActivityType;
  activityTypeId?: number;
  activityCode?: ActivityCode;
  activityCodeId?: number;
  duration?: number;
  cost?: number;
  vat?: number;
  timedDisbursement?: TimedDisbursement;
  ignoreWarning?: boolean;
  expertGroup?: ExpertGroup;
  expertGroupId?: number
  expertCode?: ExpertCode;
  expertCodeId?: number;
  counsel?: Counsel;
  counselId?: number;
  basicFull?: string;
  discretionaryEnhancement?: number;
}

export interface CostingDataTally {
  feeEarner?: FeeEarner;
  cost?: number;
  quantity?: number;
  startDate?: string;
  endDate?: string;
}

export interface CostingTotals {
  disbursements: number;
  lettersIn: number;
  lettersOut: number;
  telephoneCalls: number;
  timedActivities: number;
}

export interface CostingData {
  timedActivities?: LegalCaseActivity[];
  disbursements?: LegalCaseActivity[];
  telephoneCalls?: CostingDataTally[];
  lettersIn?: CostingDataTally[];
  lettersOut?: CostingDataTally[];
  totals?: CostingTotals;
}