import React from 'react';
import {RootStore} from './RootStore';
import {action, makeObservable, observable} from 'mobx';
import {v4 as uuidv4} from 'uuid';
import {ModalProps} from '../component/Modal';

/** Rationale: There's a lot of modals that need to be opened independent of page
 * but they still need to be tracked in a single place in case of multiple
 * modals being opened on top of each other and exist outside of the components
 * that open them. **/


export enum ModalId {
  NEW_CASE,
  CASE_EDIT,
  CLIENT_FORM,
  FEE_EARNER_FORM,
  COURT_FORM,
  CASE_ASSISTANT,
  ACTIVITY_TYPE_FORM
}

interface ModalData {
  component?: ModalProps;
  uuid?: string;
}

class ModalStore {
  modals: ModalData[];
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      modals: observable,
      addModal: action,
      removeModal: action,
      checkId: action,
      rootStore: false
    });

    this.rootStore = rootStore;
    this.modals = [];
  }

  addModal(modal: ModalProps) {
    const uuid = modal.uuid ?? uuidv4();
    this.modals.push({
      component: { ... modal, uuid },
      uuid
    });
  }

  removeModal(uuid: string) {
    this.modals = this.modals.filter(modal => modal.uuid !== uuid);
  }

  checkId(id: ModalId) : boolean {
    for (const modal of this.modals) {
      if (modal.component.id === id) return true;
    }

    return false;
  }
}

export default ModalStore;
