import {action, makeObservable, observable} from 'mobx';
import {RootStore} from './RootStore';

class ObjectStore<T> {
  data : T;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      data: observable,
      setData: action,
      editData: action,
      unsetData: action,
      rootStore: false
    });

    this.data = null;
    this.rootStore = rootStore;
  }

  setData(data: T) {
    this.data = data;
  }

  editData(data: T) {
    this.data = {
      ... this.data,
      ... data
    }
  }

  unsetData() {
    this.data = null;
  }
}


export default ObjectStore;
