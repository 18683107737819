import React, {useEffect, useState} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import API from '@api';
import useStore from '@hook/useStore';

import AuthenticationCheck from './AuthenticationCheck';
import SystemNotification, {NotificationTypes} from './SystemNotification';
import ContextMenu from './component/ContextMenu';
import Login from './component/login/Login';
import Root from './component/Root';
import Dialog from './component/Dialog';
import Modal from './component/Modal';
import UiTopLayer from './component/UiTopLayer';

export enum LocalKeys {
  CD_LOCAL_USER = 'CD_LOCAL_USER'
}

// TODO - BIG TODO
// Refactor all changeHandler functions to be like the newer ones used in Document Edit
// It's WAY cleaner

const ModalWrapper = observer(() => {
  const { modalStore } = useStore();
  return (
    <>
      {
        modalStore?.modals?.length > 0 &&
        modalStore.modals.map(modal => (
          <Modal { ... modal.component } key={ modal.component.uuid }>
            { modal.component.children }
          </Modal>
        ))
      }
    </>
  )
});

const ContextMenuWrapper = observer(() => {
  const { contextMenuStore } = useStore();
  return contextMenuStore.open && <ContextMenu />
});

const DialogWrapper = observer(() => {
  const { dialogStore } = useStore();
  return dialogStore.open && <Dialog />
});

const UiTopLayerWrapper = observer(() => {
  const { uiTopLayerStore } = useStore();
  return uiTopLayerStore.elements.length > 0 && <UiTopLayer />
})

const App = observer(() => {
  const { userStore } = useStore();
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState<boolean>(false)

  useEffect(() => {
    const localUser = localStorage.getItem(LocalKeys.CD_LOCAL_USER);
    if (!localUser) {
      navigate('/login', { replace: true });
    } else {
      API.getSelf()
        .then(response => {
          userStore.setData(response.data);
          setLoggedIn(true)
        })
        .catch(error => {
          if (error.response.status === 401)
            failedAuthHandler();
        })
    }
  }, []);

  useEffect(() => {
    if (loggedIn && !userStore.data?.hasOwnProperty("id")) {
      navigate('/login', { replace: true });
      setLoggedIn(false)
    }

    if (!loggedIn && userStore.data?.hasOwnProperty("id")) {
      setLoggedIn(true);
    }
  }, [userStore.data])

  const failedAuthHandler = () => {
    localStorage.removeItem(LocalKeys.CD_LOCAL_USER);
    SystemNotification.showMessage('Your login session has expired', NotificationTypes.ERROR);
    navigate('/login', { replace: true });
  }

  const contextMenuHandler = (e: React.MouseEvent) => {
    if ((e.target as HTMLElement).getAttribute('data-browser-context-menu') === 'true') {
      return;
    }

    e.preventDefault();
  }

  return (
    <>
      <div
        onContextMenu={ contextMenuHandler }
        className='app'>
        <AuthenticationCheck onFailedAuth={ failedAuthHandler } />
        <Routes>
          <Route path='/login' element={ <Login /> } />
          <Route path='/*' element={ <Root /> } />
        </Routes>
      </div>
      <ModalWrapper />
      <DialogWrapper />
      <ContextMenuWrapper />

      { /** This must always be the last component to render! **/ }
      <UiTopLayerWrapper />
    </>
  )
});

export default App;
