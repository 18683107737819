import React, {useEffect, useMemo, useRef, useState} from 'react';
import FocusTrap from 'focus-trap-react';
import {observer} from 'mobx-react-lite';

import useStore from '@hook/useStore';
import {V2} from '@util';

const ContextMenu = observer(() => {
  const { contextMenuStore } = useStore();
  const { position, backupParent } = contextMenuStore;
  const [closing, setClosing] = useState<boolean>(true);
  const exitTimeoutRef = useRef<NodeJS.Timeout>(null);
  const positionRef = useRef<V2>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!position) return;
    if (closing && positionRef.current !== position) {
      clearTimeout(exitTimeoutRef.current);
      positionRef.current = position;
      setClosing(false);
    }

    return () => clearTimeout(exitTimeoutRef.current);
  }, [position, backupParent]);

  const style = useMemo<React.CSSProperties>(() => {
    const heightBreak = window.innerHeight * 0.5;
    const widthBreak = window.innerWidth * 0.75;
    let x, y;

    if (position && position.x > 0 && position.y > 0) {
      x = position.x;
      y = position.y;
    } else {
      const bounds = backupParent?.getBoundingClientRect();
      x = bounds?.x + bounds.width * 0.5;
      y = bounds?.y + bounds.height * 0.5;
    }

    const hDirection = x > widthBreak ? 'right' : 'left';
    const vDirection = y > heightBreak ? 'bottom' : 'top';

    const hValue = x > widthBreak ? window.innerWidth - x : x;
    const vValue = y > heightBreak ? window.innerHeight - y : y;

    return {
      [hDirection]: hValue,
      [vDirection]: vValue
    }
  }, [position, backupParent]);

  const closeHandler = () => {
    clearTimeout(exitTimeoutRef.current);
    setClosing(true);

    exitTimeoutRef.current = setTimeout(() => {
      contextMenuStore.unsetData();
    }, 200);
  }

  const children = useMemo(() => {
    const cloneChildren = [];

    for (const child of contextMenuStore.children) {
      const childElement = child as React.ReactElement;

      cloneChildren.push({
        ... childElement,
        props: {
          ... childElement.props,
          onClick: (e: React.MouseEvent) => {
            closeHandler();
            childElement.props.onClick && childElement.props.onClick(e);
          }
        }
      });
    }

    return cloneChildren;
  }, [contextMenuStore.children]);

  return (
    <FocusTrap
      focusTrapOptions={{
        initialFocus: false,
        clickOutsideDeactivates: true,
        onDeactivate: closeHandler
      }}>
      <div className='context-menu'
           onContextMenu={ e => e.preventDefault() }
           data-closing={ closing }
           data-right-origin={ style.hasOwnProperty('right') }
           data-bottom-origin={ style.hasOwnProperty('bottom') }
           ref={ containerRef }
           style={ style }>
        { children }
      </div>
    </FocusTrap>
  )
});

export default ContextMenu;
