import React, {useEffect, useState} from 'react';
import {clearFromTopLayer, clearTopLayer, openOnTopLayer} from '../util';
import {UiTopLayerAlignment, UiTopLayerId} from '@store/UiTopLayerStore';

type WithTooltipProps = {
  disabled?: boolean;
  shortcutReminder?: string;
  tooltip: React.ReactElement | string;
  className?: string;
  children: React.ReactElement
}

const WithTooltip = ({
                       disabled,
                       shortcutReminder,
                       tooltip,
                       className,
                       children
                     }: WithTooltipProps) => {
  const [hoverUuid, setHoverUuid] = useState<string>(null);

  useEffect(() => {
    return () => {
      if (hoverUuid) {
        clearFromTopLayer(hoverUuid);
      }
    }
  }, []);

  const openTooltip = (e: React.MouseEvent<HTMLDivElement> | React.FocusEvent<HTMLDivElement>) => {
    if (disabled) return;

    // Just in case it's stuck somewhere else
    clearFromTopLayer(UiTopLayerId.TOOLTIP);

    const uuid = openOnTopLayer({
      uuid: UiTopLayerId.TOOLTIP,
      alignment: UiTopLayerAlignment.LEFT,
      parentElement: e.currentTarget,
      children: (
        <div className='tooltip-hover flex flex-row flex-align-center'>
          <p>{ tooltip }</p>
          {
            shortcutReminder &&
            <span className='shortcut'>{ shortcutReminder }</span>
          }
        </div>
      )
    });

    setHoverUuid(uuid);
  }

  const closeTooltip = () => {
    if (hoverUuid !== null) {
      clearFromTopLayer(UiTopLayerId.TOOLTIP);
      setHoverUuid(null);
    }
  }

  return (
    <div className={`with-tooltip ${className ?? ''}`}
         onFocus={ openTooltip }
         onBlur={ closeTooltip }
         onMouseDown={ closeTooltip }
         onMouseEnter={ openTooltip }
         onMouseLeave={ closeTooltip }>
      { children }
    </div>
  )
}

export default WithTooltip;
