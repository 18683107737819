import React from 'react';
import "@styles/button/button.scss";

type ButtonProps = {
  type?: "submit" | "button" | "reset";
  label?: string;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

const Button = ({ type, label, children, onClick, disabled  } : ButtonProps) => {
  return (
    <button
      type={ type || 'button' }
      className='button'
      name={ label }
      aria-label={ label }
      disabled={ disabled || false }
      onClick={ onClick }>
      { children }
    </button>
  )
}

export default Button;
