import React from 'react';
import {action, makeObservable, observable} from 'mobx';

import {V2} from '@util';
import {RootStore} from './RootStore';

/** Rationale: Easy way of rendering and tracking the global component
 *  independent of the component that opened the context menu.
 */

export interface ContextMenuProps {
  position: V2;
  backupParent?: HTMLElement;
  children?: React.ReactNode[];
}

class ContextMenuStore {
  rootStore: RootStore;
  open: boolean;
  position: V2;
  backupParent?: HTMLElement;
  children: React.ReactNode[];

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      open: observable,
      position: observable,
      backupParent: observable,
      children: observable,
      setOpen: action,
      setPosition: action,
      setBackupParent: action,
      setChildren: action,
      unsetData: action,
      rootStore: false
    });

    this.rootStore = rootStore;
  }

  setOpen(open: boolean) {
    this.open = open;
  }

  setPosition(position: V2) {
    this.position = position;
  }

  setBackupParent(parent: HTMLElement) {
    this.backupParent = parent;
  }

  setChildren(children: React.ReactNode[]) {
    this.children = children;
  }

  unsetData() {
    this.open = false;
    this.position = null;
    this.children = null;
  }
}

export default ContextMenuStore;
