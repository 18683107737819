import {makeObservable, observable, action} from 'mobx';
import {ReactNode} from 'react';
import { v4 as uuidv4 } from 'uuid';
import {RootStore} from './RootStore';

export enum UiTopLayerAlignment {
  LEFT,
  RIGHT,
  FIT
}

export enum UiTopLayerId {
  TOOLTIP
}

export interface UiTopLayerElement {
  alignment?: UiTopLayerAlignment;
  width?: number;
  parentElement: HTMLElement;
  children: ReactNode;
  uuid?: string | UiTopLayerId;
}

class UiTopLayerStore {
  elements: UiTopLayerElement[];
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      elements: observable,
      render: action,
      clear: action,
      rootStore: false
    });

    this.elements = [];
    this.rootStore = rootStore;
  }

  render(element : UiTopLayerElement) : string | UiTopLayerId {
    const uuid = element.uuid ?? uuidv4();
    this.elements.push({
      ... element,
      uuid
    });
    return uuid;
  }

  clear(uuid: string | UiTopLayerId) {
    this.elements = this.elements.filter(element => element.uuid !== uuid);
  }

  clearAll() {
    this.elements = [];
  }

  unsetData() {
    this.elements = [];
  }
}

export default UiTopLayerStore;