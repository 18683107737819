import React, {useRef} from 'react';

import API, {catchHandler, Params} from '@api';
import {Client} from '@store/ClientStore';
import {DialogActionIntent} from '@store/DialogStore';
import {ModalId} from '@store/ModalStore';
import {Pagination} from '@store/PagedDataStore';
import useAbortController from '@hook/useAbortController';

import ConfirmContact from '@component/contacts/ConfirmContact';
import DropDown from '../DropDown';
import DatabaseItemDropDown from '../database-item-drop-down/DatabaseItemDropDown';
import {ClientItem} from '../DropDownItemDisplays';
import {ModalSizes} from '@component/Modal';
import {Suspender} from '@component/Root';
import ClientForm from '@component/forms/ClientForm';
import {createDialog, createModal} from '@util';

type ClientDropDownProps = {
  id?: string;
  value: Client;
  onChange: (client: Client) => void;
  valid?: boolean;
}

const ClientDropDown = ({
                          id,
                          value,
                          onChange,
                          valid
                        } : ClientDropDownProps) => {
  const dropDownRef = useRef<HTMLDivElement>(null);
  const abortController = useAbortController();

  const getData = (params: Pagination) => {
    const extendedParams : Params = {
      ... params,
      fields: "name,town"
    }

    return API.getClients(extendedParams, abortController.getNewSignal())
      .then(response => response.data)
      .catch(catchHandler)
  }

  const changeHandler = (client: Client) => {
    createDialog({
      size: ModalSizes.LARGE,
      title: 'Is the client information correct?',
      description: <ConfirmContact contact={ client } />,
      actions: [
        {
          label: 'Yes',
          action: () => onChange(client),
          autoFocus: true,
          intent: DialogActionIntent.POSITIVE
        },
        {
          label: 'No (edit client)',
          action: () => openEditForm(client),
          intent: DialogActionIntent.NEGATIVE
        }
      ],
      onClose: focusBtn
    })
  }

  const openAddForm = () => {
    createModal({
      size: ModalSizes.LARGE,
      id: ModalId.CLIENT_FORM,
      title: 'Add a new client',
      children: <Suspender><ClientForm onSuccess={ onChange } /></Suspender>,
      onClose: focusBtn,
      backButton: true
    });
  }

  const openEditForm = (client: Client) => {
    createModal({
      size: ModalSizes.LARGE,
      id: ModalId.CLIENT_FORM,
      title: 'Edit client information',
      children: <ClientForm onSuccess={ onChange } client={ client } />,
      onClose: focusBtn,
      backButton: true
    });
  }

  const focusBtn = () => {
    const btnId = id ?? 'dd_client-drop-down';
    document.getElementById(btnId)?.focus();
  }

  return (
    <DropDown
      id={ id ?? 'dd_client-drop-down' }
      ref={ dropDownRef }
      valid={ valid }
      getValue={ () => value?.name || 'Select a client' }>
      <DatabaseItemDropDown<Client>
        getData={ getData }
        onChange={ changeHandler }
        label='Client'
        displayItemAs={ item => <ClientItem client={ item } /> }
        value={ value }
        onOpenAdd={ openAddForm }
      />
    </DropDown>
  )
}

export default ClientDropDown;
