import React from 'react';
import dayjs from 'dayjs';

import {Contact} from '@store/ContactStore';

type ConfirmContactProps = {
  contact: Contact;
}

const Value = ({ value } : { value: string }) => (
  <span className='value' data-empty={ !value || value.length <= 0 }>
    { value ?? 'Not set' }
  </span>
)

const ConfirmContact = ({ contact } : ConfirmContactProps) => {
  return (
    <div className='confirm-contact'>
      <div className='flex flex-row'>
        <ul className='half'>
          {
            "name" in contact &&
            <li className='flex flex-row'>
              <span className='label'>Name:</span>
              <Value value={ contact.name } />
            </li>
          }
          {
            "firstName" in contact &&
            <li className='flex flex-row'>
              <span className='label'>First Name:</span>
              <Value value={ contact.firstName } />
            </li>
          }
          {
            "lastName" in contact &&
            <li className='flex flex-row'>
              <span className='label'>Last Name:</span>
              <Value value={ contact.lastName } />
            </li>
          }
          {
            "client" in contact &&
            <li className='flex flex-row'>
              <span className='label'>Client:</span>
              <Value value={ contact.client.name } />
            </li>
          }
          {
            "addressLine1" in contact &&
            <li className='flex flex-row'>
              <span className='label'>Address Line 1:</span>
              <Value value={ contact.addressLine1 } />
            </li>
          }
          {
            "addressLine2" in contact &&
            <li className='flex flex-row'>
              <span className='label'>Address Line 2:</span>
              <Value value={ contact.addressLine2 } />
            </li>
          }
          {
            "town" in contact &&
            <li className='flex flex-row'>
              <span className='label'>Town/City:</span>
              <Value value={ contact.town } />
            </li>
          }
          {
            "county" in contact &&
            <li className='flex flex-row'>
              <span className='label'>County:</span>
              <Value value={ contact.county } />
            </li>
          }
          {
            "country" in contact &&
            <li className='flex flex-row'>
              <span className='label'>Country:</span>
              <Value value={ contact.country } />
            </li>
          }
          {
            "postcode" in contact &&
            <li className='flex flex-row'>
              <span className='label'>Postcode:</span>
              <Value value={ contact.postcode } />
            </li>
          }
          {
            "emailAddress" in contact &&
            <>
              <li className='flex flex-row'>
                <span className='label'>Email Address:</span>
                <Value value={ contact.emailAddress } />
              </li>
              <li className='flex flex-row'>
                <span className='label'>Telephone Number:</span>
                <Value value={ contact.telephoneNumber } />
              </li>
            </>
          }
        </ul>
        <ul className='half'>
          {
            "contactName" in contact &&
            <li className='flex flex-row'>
              <span className='label'>Contact Name:</span>
              <Value value={ contact.contactName } />
            </li>
          }
          {
            "contactEmail" in contact &&
            <>
              <li className='flex flex-row'>
                <span className='label'>Contact Email:</span>
                <Value value={ contact.contactEmail } />
              </li>
              <li className='flex flex-row'>
                <span className='label'>Telephone Number:</span>
                <Value value={ contact.telephoneNumber } />
              </li>
            </>
          }
          {
            "fax" in contact &&
            <li className='flex flex-row'>
              <span className='label'>Fax Number:</span>
              <Value value={ contact.fax } />
            </li>
          }
          {
            "dxNumber" in contact &&
            <li className='flex flex-row'>
              <span className='label'>DX Number:</span>
              <Value value={ contact.dxNumber } />
            </li>
          }
          {
            "laaAccountNumber" in contact &&
            <li className='flex flex-row'>
              <span className='label'>LAA Account No:</span>
              <Value value={ contact.laaAccountNumber } />
            </li>
          }
          {
            "legalSocietyRoleNumber" in contact &&
            <li className='flex flex-row'>
              <span className='label'>Legal Society Role No:</span>
              <Value value={ contact.legalSocietyRoleNumber } />
            </li>
          }
          {
            "qualificationDate" in contact &&
            <li className='flex flex-row'>
              <span className='label'>Qualification Date:</span>
              <Value value={ contact.qualificationDate && dayjs(contact.qualificationDate).format('DD/MM/YYYY') } />
            </li>
          }
        </ul>
      </div>
      <div className='notes'>
        <span className='title'>Notes:</span>
        <p data-empty={ !contact.notes || contact.notes.length <= 0 }>
          { contact.notes ?? 'None' }
        </p>
      </div>
    </div>
  )
}

export default ConfirmContact;
