import React, {useState} from 'react';

import '@styles/input/checkbox.scss';

type CheckBoxProps = {
  id: string;
  name: string;
  label: string;
  checked: boolean;
  onChange: (value: boolean, name?: string) => void;
}

const CheckBox = ({
                    id,
                    name,
                    label,
                    checked,
                    onChange
                  } : CheckBoxProps) => {
  const [focus, setFocus] = useState<boolean>();

  const focusHandler = () => {
    setFocus(true);
  }

  const blurHandler = () => {
    setFocus(false);
  }

  const changeHandler = () => {
    onChange(!checked, name);
  }

  return (
    <label
      htmlFor={ id }
      className='check-box flex flex-row flex-align-center'
      role='checkbox'>
      <div className='check-box__input'
           data-checked={ checked }
           data-focus={ focus }>
        <input
          id={ id }
          type='checkbox'
          name={ name }
          checked={ checked }
          onChange={ changeHandler }
          onFocus={ focusHandler }
          onBlur={ blurHandler }
        />
      </div>
      <span className='label'>{ label }</span>
    </label>
  )
}

export default CheckBox;
