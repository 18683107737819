export default {
  GENERAL: 200,
  POSTCODE: 12,
  FAX: 20,
  DX_NUMBER: 60,
  COUNTRY: 100,
  LAA_ACCOUNT_NUMBER: 40,
  INITIALS: 4,
  LEGAL_SOCIETY_ROLE_NUMBER: 40,
  TELEPHONE_NUMBER: 20,
  CLIENT_REFERENCE: 100,
  CD_REFERENCE: 50,
  CASE_TITLE: 255
}