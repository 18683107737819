import React, {useEffect, useRef, useState} from 'react';
import Events from '@events';

export default (
  element: HTMLElement,
  callback: (stop: () => void) => void,
  condition = true,
  deps : React.DependencyList = []
) => {
  const [scrolling, setScrolling] = useState<boolean>(false);
  const animationFrameRef = useRef<number>(null);
  const timeoutRef = useRef<NodeJS.Timeout>(null);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
      cancelAnimationFrame(animationFrameRef.current);
    }
  }, []);

  useEffect(() => {
    element?.addEventListener(Events.SCROLL, scrollHandler);
    return () => element?.removeEventListener(Events.SCROLL, scrollHandler);
  }, [ ...deps, element ]);

  useEffect(() => {
    if (scrolling && condition) animationFrameRef.current = requestAnimationFrame(animationHandler);
    else cancelAnimationFrame(animationFrameRef.current);
  }, [scrolling, condition]);

  const scrollHandler = () => {
    clearTimeout(timeoutRef.current);
    setScrolling(true);

    timeoutRef.current = setTimeout(() => {
      setScrolling(false);
    }, 150);
  }

  const stop = () => {
    setScrolling(false);
    cancelAnimationFrame(animationFrameRef.current);
  }

  const animationHandler = () => {
    callback(stop);
    animationFrameRef.current = requestAnimationFrame(animationHandler);
  }
}
