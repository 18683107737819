import React, {useEffect} from 'react';
import Events from '@events';

export default (key: string, callback: (e: KeyboardEvent) => void, deps?: React.DependencyList) => {
  useEffect(() => {
    window.addEventListener(Events.KEY_DOWN, keyDownHandler);
    return () => window.removeEventListener(Events.KEY_DOWN, keyDownHandler);
  }, deps ?? []);

  const keyDownHandler = (e: KeyboardEvent) => {
    if (!e.altKey) return;
    if (e.key.toLowerCase() === key.toLowerCase()) callback(e);
  }
}
