import React, {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';

import API, {ApiRoutes, catchHandler} from '@api';
import {Client} from '@store/ClientStore';
import {ModalContext} from '@context/ModalContext';
import useStore from '@hook/useStore';
import useKeyboardShortcut from '@hook/useKeyboardShortcut';
import useAbortController from '@hook/useAbortController';

import TextInput from '@component/input/TextInput';
import Button from '@component/button/Button';
import LoadingCircle from '@component/loading/LoadingCircle';
import Textarea from '../input/Textarea';
import FieldLengths from '../../FieldLengths';
import {getPatchObject} from '../../util';

type ClientFormProps = {
  client?: Client;
  onSuccess: (client: Client) => void;
}

const ClientForm = observer(({ client, onSuccess } : ClientFormProps) => {
  const { apiStore, modalStore } = useStore();
  const [busy, setBusy] = useState<boolean>(false);
  const [formData, setFormData] = useState<Client>(client ?? {});
  const [checkInputs, setCheckInputs] = useState<boolean>(null);
  const modalContext = useContext(ModalContext);
  const abortController = useAbortController();

  useEffect(() => {
    setFormData(client ?? {});
  }, [client]);

  useEffect(() => {
    setBusy(apiStore.checkBusy(ApiRoutes.CLIENTS));
  }, [apiStore.busyRoutes.length]);

  const changeHandler = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ... formData,
      [e.currentTarget.name]: e.currentTarget.value
    });
  }

  const submitHandler = () => {
    setCheckInputs(true);
    setTimeout(() => setCheckInputs(false), 50);

    if (!formData.name || formData.name.length <= 0) {
      document.getElementById('cl_name')?.focus();
      return;
    }

    const apiMethod = client ?  API.patchClient : API.postClient;
    const apiObject = client ? getPatchObject(client, formData) : formData;

    apiMethod(apiObject, abortController.getNewSignal())
      .then(response => {
        modalStore.removeModal(modalContext.data.uuid);
        onSuccess(response.data)
      })
      .catch(catchHandler);
  }

  useKeyboardShortcut('Enter', submitHandler, [formData]);

  const submitText = client ? 'Save Details' : 'Add Client';

  return (
    <div className='form'
         role='form'
         data-busy={ busy }>
      <div className='flex flex-row'>
        <div className='half'>
          <TextInput
            id='cl_name'
            name='name'
            value={ formData.name ?? '' }
            maxLength={ FieldLengths.GENERAL }
            label='Client Name'
            onChange={ changeHandler }
            autoFocus={ true }
            required={ true }
            forceCheckValid={ checkInputs }
          />
          <TextInput
            name='addressLine1'
            value={ formData.addressLine1 ?? '' }
            maxLength={ FieldLengths.GENERAL }
            label='Address Line 1'
            onChange={ changeHandler }
          />
          <TextInput
            name='addressLine2'
            value={ formData.addressLine2 ?? '' }
            maxLength={ FieldLengths.GENERAL }
            label='Address Line 2'
            onChange={ changeHandler }
          />
          <TextInput
            name='town'
            value={ formData.town ?? '' }
            maxLength={ FieldLengths.GENERAL }
            label='Town/City'
            onChange={ changeHandler }
          />
          <TextInput
            name='county'
            value={ formData.county ?? '' }
            maxLength={ FieldLengths.GENERAL }
            label='County'
            onChange={ changeHandler }
          />
          <TextInput
            name='country'
            value={ formData.country ?? '' }
            maxLength={ FieldLengths.COUNTRY }
            label='Country'
            onChange={ changeHandler }
          />
          <TextInput
            name='postcode'
            value={ formData.postcode ?? '' }
            maxLength={ FieldLengths.POSTCODE }
            label='Postcode'
            onChange={ changeHandler }
          />
        </div>
        <div className='half'>
          <TextInput
            name='contactName'
            value={ formData.contactName ?? '' }
            maxLength={ FieldLengths.GENERAL }
            label='Contact Name'
            onChange={ changeHandler }
          />
          <TextInput
            name='contactEmail'
            value={ formData.contactEmail ?? '' }
            maxLength={ FieldLengths.GENERAL }
            label='Contact Email'
            onChange={ changeHandler }
          />
          <TextInput
            name='telephoneNumber'
            value={ formData.telephoneNumber ?? '' }
            maxLength={ FieldLengths.TELEPHONE_NUMBER }
            label='Telephone Number'
            onChange={ changeHandler }
          />
          <TextInput
            name='fax'
            value={ formData.fax ?? '' }
            maxLength={ FieldLengths.FAX }
            label='Fax Number'
            onChange={ changeHandler }
          />
          <TextInput
            name='dxNumber'
            value={ formData.dxNumber ?? '' }
            maxLength={ FieldLengths.DX_NUMBER }
            label='DX Number'
            onChange={ changeHandler }
          />
          <TextInput
            name='laaAccountNumber'
            value={ formData.laaAccountNumber ?? '' }
            maxLength={ FieldLengths.LAA_ACCOUNT_NUMBER }
            label='LAA Account Number'
            onChange={ changeHandler }
          />
        </div>
      </div>
      <Textarea
        name='notes'
        label='Notes'
        value={ formData.notes ?? ''}
        onChange={ changeHandler }
      />
      <div className='button-row flex flex-row flex-center'>
        <Button
          onClick={ submitHandler }
          label={ submitText }>
          { submitText }
        </Button>
      </div>
      {
        busy &&
        <LoadingCircle />
      }
    </div>
  )
});

export default ClientForm;
