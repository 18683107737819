import React, {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';

import API, {ApiRoutes, catchHandler} from '@api';
import useStore from '@hook/useStore';
import useKeyboardShortcut from '@hook/useKeyboardShortcut';
import {FeeEarner} from '@store/FeeEarnerStore';
import {Client} from '@store/ClientStore';
import useAbortController from '@hook/useAbortController';
import {ModalContext} from '@context/ModalContext';

import TextInput from '@component/input/TextInput';
import Button from '@component/button/Button';
import LoadingCircle from '@component/loading/LoadingCircle';
import CheckBox from '@component/input/CheckBox';
import ClientDropDown from '@component/drop-down/common-drop-downs/ClientDropDown';
import DatePicker, {INVALID_DATE} from '@component/date-picker/DatePicker';
import Textarea from '@component/input/Textarea';
import FieldLengths from '../../FieldLengths';
import {getPatchObject} from '@util';
import DropDown from '../drop-down/DropDown';
import DropDownSimpleList from '../drop-down/DropDownSimpleList';
import { StatutoryEnhancementTypes } from '../../NonDBConsts';

type NewFeeEarnerFormProps = {
  legalCaseId?: number;
  feeEarner?: FeeEarner;
  clientPrefill?: Client;
  onSuccess: (feeEarner: FeeEarner) => void;
}

const FeeEarnerForm = observer(({
                                  legalCaseId,
                                  feeEarner,
                                  clientPrefill,
                                  onSuccess
                                } : NewFeeEarnerFormProps) => {
  const initialData : FeeEarner = {
    client: clientPrefill,
    hasStatutoryEnhancement: false
  };
  const { apiStore, modalStore } = useStore();
  const [formData, setFormData] = useState<FeeEarner>(feeEarner ?? initialData);
  const [busy, setBusy] = useState<boolean>(false);
  const [checkInputs, setCheckInputs] = useState<boolean>(null);
  const modalContext = useContext(ModalContext);
  const abortController = useAbortController();

  useEffect(() => {
    setFormData(feeEarner ?? initialData);
  }, [feeEarner]);

  useEffect(() => {
    setBusy(apiStore.checkBusy(ApiRoutes.FEE_EARNERS));
  }, [apiStore.busyRoutes.length]);

  const changeHandler = (newFormData: FeeEarner) => {
    setFormData({
      ... formData,
      ... newFormData
    });
  }

  const submitHandler = () => {
    setCheckInputs(true);
    setTimeout(() => setCheckInputs(false), 50);

    if (feeEarner) {
      editFeeEarner();
      return;
    }

    if (!formData.firstName || formData.firstName.length <= 0) {
      document.getElementById('fe_first-name')?.focus();
      return;
    }

    if (!formData.lastName || formData.lastName.length <= 0) {
      document.getElementById('fe_last-name')?.focus();
      return;
    }

    if (!legalCaseId && !formData.client) {
      document.getElementById('dd_client-drop-down')?.focus();
      return;
    }

    if (formData.qualificationDate === INVALID_DATE) {
      document.getElementById('fe_qualification-date')?.focus();
      return;
    }

    const feeEarnerData: FeeEarner = {
      ... formData,
      clientId: formData.client.id
    };

    if (legalCaseId) {
      feeEarnerData.legalCaseId = legalCaseId;
    }

    API.postFeeEarner(feeEarnerData, abortController.getNewSignal())
      .then(response => {
        modalStore.removeModal(modalContext.data.uuid);
        onSuccess(response.data as FeeEarner);
      })
      .catch(catchHandler);
  }

  const editFeeEarner = () => {
    const patchObject = getPatchObject(feeEarner, formData);

    if (patchObject.firstName && patchObject.firstName.length < 0) {
      document.getElementById('fe_first-name')?.focus();
      return;
    }

    if (patchObject.lastName && patchObject.lastName.length < 0) {
      document.getElementById('fe_last-name')?.focus();
      return;
    }

    if (patchObject.client) {
      patchObject.clientId = patchObject.client.id;
      delete patchObject.client;
    }

    API.patchFeeEarner(
      patchObject,
      { legalCaseId },
      abortController.getNewSignal()
    )
      .then(response => {
        modalStore.removeModal(modalContext.data.uuid);
        onSuccess(response.data as FeeEarner);
      })
      .catch(catchHandler)
  }

  useKeyboardShortcut('Enter', submitHandler, [formData]);

  const submitText = feeEarner ? 'Save Details' : 'Add Fee Earner';

  return (
    <div className='form' data-busy={ busy }>
      <div className='flex flex-row'>
        <div className='half'>
          <TextInput
            id='fe_first-name'
            name='firstName'
            label='First Name'
            value={ formData.firstName ?? '' }
            maxLength={ FieldLengths.GENERAL }
            onChange={ e => changeHandler({ firstName: e.currentTarget.value }) }
            autoFocus={ true }
            forceCheckValid={ checkInputs }
            required={ true }
          />
          <TextInput
            id='fe_last-name'
            name='lastName'
            label='Last Name'
            value={ formData.lastName ?? '' }
            maxLength={ FieldLengths.GENERAL }
            onChange={ e => changeHandler({ lastName: e.currentTarget.value }) }
            forceCheckValid={ checkInputs }
            required={ true }
          />
          {
            !legalCaseId &&
            <ClientDropDown
              value={ formData?.client }
              onChange={ client => changeHandler({ client }) }
              valid={ checkInputs !== null ? typeof formData.client !== 'undefined' : null }
            />
          }
          <TextInput
            type='email'
            name='emailAddress'
            label='Email Address'
            value={ formData.emailAddress ?? '' }
            maxLength={ FieldLengths.GENERAL }
            onChange={ e => changeHandler({ emailAddress: e.currentTarget.value }) }
          />
          <TextInput
            type='tel'
            name='telephoneNumber'
            label='Telephone Number'
            value={ formData.telephoneNumber ?? '' }
            maxLength={ FieldLengths.TELEPHONE_NUMBER }
            onChange={ e => changeHandler({ telephoneNumber: e.currentTarget.value }) }
          />
        </div>
        <div className='half'>
          <TextInput
            name='fax'
            label='Fax Number'
            value={ formData.fax ?? '' }
            maxLength={ FieldLengths.FAX }
            onChange={ e => changeHandler({ fax: e.currentTarget.value }) }
          />
          <TextInput
            name='legalSocietyRoleNumber'
            label='Legal Society Role Number'
            value={ formData.legalSocietyRoleNumber ?? '' }
            maxLength={ FieldLengths.LEGAL_SOCIETY_ROLE_NUMBER }
            onChange={ e => changeHandler({ legalSocietyRoleNumber: e.currentTarget.value }) }
          />
          <DatePicker
            id='fe_qualification-date'
            label='Qualification Date'
            value={ formData.qualificationDate }
            onChange={ qualificationDate => changeHandler({ qualificationDate }) }
          />
          <CheckBox
            id='fef_statutory'
            name='statutoryEnhancement'
            label='Statutory Enhancement?'
            checked={ formData.hasStatutoryEnhancement }
            onChange={ hasStatutoryEnhancement => changeHandler({ hasStatutoryEnhancement }) }
          />
          {
            formData.hasStatutoryEnhancement &&
            <DropDown
              getValue={ () => formData.statutoryEnhancementType ?? 'Statutory Enhancement Type...' }>
              <DropDownSimpleList
                items={ StatutoryEnhancementTypes }
                onSelect={ statutoryEnhancementType => changeHandler({ statutoryEnhancementType: statutoryEnhancementType.name })}
              />
            </DropDown>
          }
        </div>
      </div>
      <Textarea
        name='notes'
        label='Notes'
        value={ formData.notes ?? ''}
        onChange={ e => changeHandler({ notes: e.currentTarget.value }) }
      />
      <div className='button-row flex flex-row flex-center'>
        <Button onClick={ submitHandler }>
          { submitText }
        </Button>
      </div>
      {
        busy &&
        <LoadingCircle />
      }
    </div>
  )
});

export default FeeEarnerForm;
