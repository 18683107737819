import React, {useEffect, useMemo, useRef, useState} from 'react';
import dayjs, {Dayjs} from 'dayjs';
import {v4 as uuidv4} from 'uuid';

import {UiTopLayerAlignment} from '@store/UiTopLayerStore';
import DatePickerCalendar from './DatePickerCalendar';

import {clearFromTopLayer, openOnTopLayer} from '@util';
import '@styles/input/date-picker.scss';

type DatePickerProps = {
  id?: string;
  label?: string;
  value: string;
  min?: Dayjs;
  max?: Dayjs;
  onChange: (date: string) => void;
  onFullDateChange?: (date: string) => void;
}

export const INVALID_DATE = 'Invalid Date';

const DatePicker = ({ id, label, value, onChange, onFullDateChange, min, max } : DatePickerProps) => {
  const [inputId] = useState<string>(id ?? uuidv4());
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const [calendarUuid, setCalendarUuid] = useState<string>(null);
  const getValueTimeoutRef = useRef<NodeJS.Timeout>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const dayInputRef = useRef<HTMLInputElement>(null);
  const monthInputRef = useRef<HTMLInputElement>(null);
  const yearInputRef = useRef<HTMLInputElement>(null);
  const inputHandleTimeoutRef = useRef<NodeJS.Timeout>(null);

  const date = value && value !== INVALID_DATE ? dayjs(value) : null;

  const getDateValue = () => {
    const day = dayInputRef.current?.value ? parseInt(dayInputRef.current.value) : null;
    const month = monthInputRef.current?.value ? parseInt(monthInputRef.current.value) : null;
    const year = yearInputRef.current?.value ? parseInt(yearInputRef.current.value) : null;

    if (!day && !month && !year) {
      onChange(null);
      return;
    }

    if (!day || !month || !year) {
      onChange(INVALID_DATE);
      return;
    }

    onChange(dayjs().date(day).month(month - 1).year(year).startOf('day').format());
  }

  const keyUpHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length <= 0) return;

    clearTimeout(inputHandleTimeoutRef.current);
    clearTimeout(getValueTimeoutRef.current);

    const value = e.currentTarget.value;
    const name = e.currentTarget.name;

    inputHandleTimeoutRef.current = setTimeout(() => {
      if (/[0-9]/.test(e.key)) {
        if (name === 'day' &&
          value.length === 2) {
          monthInputRef.current?.focus();
          e.stopPropagation();
        }

        if (name === 'month' &&
          value.length === 2) {
          yearInputRef.current?.focus();
          e.stopPropagation();
        }
      }

      getValueTimeoutRef.current = setTimeout(() => {
        getDateValue();
      }, 500);
    }, 250);
  }

  const focusHandler = () => {
    setHasFocus(true);
  }

  const blurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.currentTarget.name === 'year' &&
      e.currentTarget.value.length >= 1 &&
      e.currentTarget.value.length < 4) {
      const remaining = 4 - e.currentTarget.value.length;
      e.currentTarget.value = date?.year().toString().substring(0, remaining) + e.currentTarget.value;
    }

    if (e.currentTarget.value.length === 1) {
      e.currentTarget.value = '0' + e.currentTarget.value;
    }

    clearTimeout(getValueTimeoutRef.current);
    getDateValue();
    setHasFocus(false);
  }

  const leapYear = (y: number) => (y % 4 === 0) && (y % 100 !== 0) || (y % 400 === 0);

  const dayMax = useMemo(() => {
    const inputValue = monthInputRef.current?.value
    const month = inputValue ? parseInt(monthInputRef.current?.value) - 1 : date?.month();
    const year = parseInt(yearInputRef.current?.value) ?? date?.year();

    if (leapYear(year) && month === 1) return 29;
    if (month === 1) return 28;
    if (month % 2 === 0 || month === 7) return 31;
    return 30;
  }, [monthInputRef.current?.value]);

  useEffect(() => {
    if (parseInt(dayInputRef.current.value) > dayMax) {
      dayInputRef.current.value = dayMax.toString();
    }
  }, [monthInputRef.current?.value, yearInputRef.current?.value]);

  useEffect(() => {
    if (calendarOpen) {
      if (calendarUuid) clearFromTopLayer(calendarUuid);

      const uuid = openOnTopLayer({
        parentElement: containerRef.current,
        children: <DatePickerCalendar
          value={ date }
          min={ min }
          max={ max }
          onChange={ calendarChangeHandler }
          onClose={ () => setCalendarOpen(false) }
        />,
        alignment: UiTopLayerAlignment.RIGHT
      });

      setCalendarUuid(uuid);
    } else {
      clearFromTopLayer(calendarUuid);
      setCalendarUuid(null);
    }
  }, [calendarOpen]);

  const toggleCalendar = () => {
    setCalendarOpen(!calendarOpen);
  }

  const calendarChangeHandler = (date: Dayjs, ignoreFullChange = false) => {
    onChange(date.format());
    if (!ignoreFullChange && onFullDateChange) onFullDateChange(date.format());

    dayInputRef.current.value = date.format('DD');
    monthInputRef.current.value = date.format('MM');
    yearInputRef.current.value = date.format('YYYY');
  }

  return (
    <div className='date-picker'
         ref={ containerRef }
         data-has-focus={ hasFocus }>
      <div className='input date-input'>
        {
          label &&
          <label className='label'>{ label }</label>
        }
        <div className='inputs flex flex-row flex-align-center'>
          <input
            id={ inputId }
            ref={ dayInputRef }
            type='number'
            aria-label='Day'
            name='day'
            placeholder='DD'
            defaultValue={ date?.format('DD') ?? '' }
            max={ dayMax }
            min={1}
            pattern='[0-9]{2}'
            onKeyUp={ keyUpHandler }
            onFocus={ focusHandler }
            onBlur={ blurHandler }
          />
          <span className='divider'>/</span>
          <input
            ref={ monthInputRef }
            type='number'
            aria-label='Month'
            name='month'
            placeholder='MM'
            defaultValue={ date?.format('MM') ?? '' }
            max={12}
            min={1}
            pattern='[0-9]{2}'
            onKeyUp={ keyUpHandler }
            onFocus={ focusHandler }
            onBlur={ blurHandler }
          />
          <span className='divider'>/</span>
          <input
            ref={ yearInputRef }
            type='number'
            aria-label='Year'
            name='year'
            placeholder='YYYY'
            defaultValue={ date?.format('YYYY') ?? '' }
            min={ 1970 }
            max={ 9999 }
            pattern='[0-9]{4}'
            onKeyUp={ keyUpHandler }
            onFocus={ focusHandler }
            onBlur={ blurHandler }
          />
          { /** This max will need checking in a few thousand years please be diligent **/ }
        </div>
      </div>

      <button type='button'
              aria-label='Open Calendar'
              onClick={ toggleCalendar }
              className='date-picker__toggle-calendar'>
        <img src='/img/svg/calendar.svg' alt='Calendar' />
      </button>

      {
        value === INVALID_DATE &&
        <div className='error'>Please enter the date in DD/MM/YYYY format</div>
      }
    </div>
  )
}

export default DatePicker;
