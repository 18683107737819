import React from 'react';

export default () => (
  <div className='top-bar top-bar-fallback flex flex-row flex-align-center'>
    <div className='dummy-drop-down'>&nbsp;</div>
    <div className='top-bar-actions flex flex-row flex-align-center'>
      <div className='top-bar-actions__link'>&nbsp;</div>
      <div className='top-bar-actions__link top-bar-search'>&nbsp;</div>
      <div className='top-bar-actions__link'>&nbsp;</div>
    </div>
    <div className='top-bar-account'>
      <div className='top-bar-account__circle'>&nbsp;</div>
    </div>
  </div>
)
